import { useQuery } from "@apollo/client";
import { Box, Grid } from "@mui/material";
import React from "react";
import { QUERY_GET_SELLINGPRODUCTS_BY_BRAND } from "../../../../../graphql/Query";
import ModelBrand from "./ModelBrand";
import { useRecoilState } from "recoil";
import { brandCategoryState } from "../../../../../store/atoms/selling/brands/brandsAtom";

const AllModelsBrands = () => {
  const [brandCategory, setBrandCategory] = useRecoilState(brandCategoryState);
  console.log("This is in all Models", brandCategory);
  const { data, loading, error } = useQuery(
    QUERY_GET_SELLINGPRODUCTS_BY_BRAND,
    { variables: { brandName: "Samsung (Tablets)" } }
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <Box>
      <Grid container spacing={{ xs: 1, laptop: 3 }}>
        {data?.getProductByBrand.map((product) => (
          <Grid
            item
            xs={4}
            laptop={4}
            tabletLarge={3}
            desktop={2.4}
            key={product.id}
          >
            <ModelBrand product={product} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AllModelsBrands;
