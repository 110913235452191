import React from "react";
import { Box, Grid } from "@mui/material";
import CustomTypography from "../../muicomponents/shared/CustomTypography";
const BottomNavbar = ({ navItems, onItemClick }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "75px",
        padding: "1rem 0",
        backgroundColor: "#fff",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px -3px 8px",
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          maxWidth: "1280px",
          margin: "0 0.75rem",
        }}
      >
        <Grid container spacing={2}>
          {navItems.map((item, index) => (
            <Grid key={index} item xs={2.4} onClick={() => onItemClick(item)}>
              <Box
                display="flex"
                flexDirection={"column"}
                gap="0rem"
                alignItems="center"
                justifyContent={"center"}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                {item.icon && (
                  <Box
                    sx={{
                      ...(item.label === "Profile" && {
                        position: "absolute",
                        bottom: "40px",
                        border: "none",
                        outline: "none",
                        width: { xs: "60px", tabletMedium: "80px" },
                        height: { xs: "60px", tabletMedium: "80px" },
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        background: "rgb(6, 155, 170)",
                        fontSize: "20px",
                        fontWeight: "600",
                        border: "1px solid gray",
                      }),
                    }}
                  >
                    {item.icon}
                  </Box>
                )}
                <CustomTypography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "600",
                    fontSize: "0.875rem",
                    ...(item.label === "Profile" && {
                      position: "relative",
                      top: "24px",
                    }),
                  }}
                >
                  {item.label}
                </CustomTypography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default BottomNavbar;
