import React from "react";
import { Box } from "@mui/material";
import HomePageContainer from "../shared/HomePageContainer";
import Heading from "../shared/Heading";
import AllNews from "./AllNews";

const News = () => {
  return (
    <HomePageContainer sx={{ margin: { xs: "0 0.5rem", laptop: 0 } }}>
      <Heading text={"News"} />
      <AllNews />
    </HomePageContainer>
  );
};

export default News;
