import React from "react";
import DoorStepBanner from "../../../../../Images/home/doorstepBanner/DoorStepBanner.png";
import CallBanner from "../../../../../Images/home/doorstepBanner/CallBanner.png";
import { Box } from "@mui/material";

const DoorstepBanner = () => {
  return (
    <Box display={"flex"} gap={"0.5rem"}>
      <Box flexBasis={"30%"} sx={{ width: "100%", height: "100%" }}>
        <img src={CallBanner} style={{ width: "100%", height: "100%" }} />
      </Box>
      <Box flexBasis={"70%"} sx={{ width: "100%", height: "100%" }}>
        <img src={DoorStepBanner} style={{ width: "100%", height: "100%" }} />
      </Box>
    </Box>
  );
};

export default DoorstepBanner;
