/* eslint-disable jsx-a11y/alt-text */
import Navigation from "../../Navigation";
import { useQuery, useLazyQuery } from "@apollo/client";
import {
  Container,
  Col,
  Row,
  Breadcrumb,
  Spinner,
  Dropdown,
} from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { QUERY_GET_PRODUCTS } from "../../../graphql/Query";
import { QUERY_GET_SERIES_BY_BRAND } from "../../../graphql/Query";
import { FaSearch } from "react-icons/fa";

import { QUERY_GET_PRODUCT_BY_SERIES } from "../../../graphql/Query";
import { GET_SEARCH_SELL_PRODUCTS } from "../../../graphql/Query";
import { useEffect, useState } from "react";
import Footer from "../../Footer";
import "../../../ComponentsCss/Brandpage/productBrand.css";
import PhoneQuote from "../../PhonesQuoteForm/PhonesQuote";
const ProductBrand = () => {
  const [selectedSeries, setSelectedSeries] = useState("");
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();

  const navigate = useNavigate();
  const { brandname } = useParams();
  const [searchSellProduct, { data: searchData, loading: productLoading2 }] =
    useLazyQuery(GET_SEARCH_SELL_PRODUCTS);

  const { data: getSeriesByBrand, loading: getseriesloading } = useQuery(
    QUERY_GET_SERIES_BY_BRAND,
    {
      variables: {
        productBrand: brandname,
      },
    }
  );
  const [
    getProductBySeries,
    { data: productBySeriesData, loading: productLoading },
  ] = useLazyQuery(QUERY_GET_PRODUCT_BY_SERIES);

  // const { data: getProducts, loading: getProductloading } = useQuery(QUERY_GET_PRODUCTS,
  //     {
  //         variables:
  //         {
  //             "productBrand": brandname,
  //             "category": location.state.productCategory
  //         }
  //     });

  const handleSelect = (product, id) => {
    if (location.state?.productCategory === "Laptops") {
      navigate("/alllaptopproduct/" + id, {
        state: {
          productCategory: location.state?.productCategory,
          productBrand: location.state?.productBrand,
          productName: product,
        },
      });
    } else if (location.state?.productCategory === "Phones") {
      navigate("/allproduct/" + id, {
        state: {
          productCategory: location.state?.productCategory,
          productBrand: location.state?.productBrand,
          productName: product,
        },
      });
    } else if (location.state?.productCategory === "Smart Watches") {
      navigate("/smartwatch-price/" + id, {
        state: {
          productCategory: location.state?.productCategory,
          productBrand: location.state?.productBrand,
          productName: product,
        },
      });
    } else if (location.state?.productCategory === "Tablets") {
      navigate("/allproduct/" + id, {
        state: {
          productCategory: location.state?.productCategory,
          productBrand: location.state?.productBrand,
          productName: product,
        },
      });
    } else if (location.state?.productCategory === "Desktop/Imac") {
      navigate("/desktop-show-price/" + id, {
        state: {
          productCategory: location.state?.productCategory,
          productBrand: location.state?.productBrand,
          productName: product,
        },
      });
    }
  };
  const handleProductDropdown = (series) => {
    getProductBySeries({
      variables: {
        seriesName: series,
      },
    });
  };

  const handleSearch = (val) => {
    setSearchInput(val);
    if (searchInput) {
      searchSellProduct({
        variables: {
          name: `${val}`,
          category: location.state?.productCategory,
        },
      });
    }
  };
  const handleSelect2 = (product, id, brand) => {
    if (location.state?.productCategory === "Laptops") {
      navigate("/alllaptopproduct/" + id, {
        state: {
          productCategory: location.state?.productCategory,
          productBrand: brand,
          productName: product,
        },
      });
    } else if (location.state?.productCategory === "Phones") {
      navigate("/allproduct/" + id, {
        state: {
          productCategory: location.state?.productCategory,
          productBrand: brand,
          productName: product,
        },
      });
    } else if (location.state?.productCategory === "Smart Watches") {
      navigate("/smartwatch-price/" + id, {
        state: {
          productCategory: location.state?.productCategory,
          productBrand: brand,
          productName: product,
        },
      });
    } else if (location.state?.productCategory === "Tablets") {
      navigate("/allproduct/" + id, {
        state: {
          productCategory: location.state?.productCategory,
          productBrand: brand,
          productName: product,
        },
      });
    } else if (location.state?.productCategory === "Desktop/Imac") {
      navigate("/desktop-show-price/" + id, {
        state: {
          productCategory: location.state?.productCategory,
          productBrand: brand,
          productName: product,
        },
      });
    }
  };

  let productCategory = location.state?.productCategory;
  const hendleSelectProduct = () => {
    if (productCategory === "Desktop/Imac") {
      productCategory = "Desktop-Imac";
    }
    navigate(`/selling/${productCategory}`, {});
  };
  return (
    <>
      <Navigation />
      <div className="banner">
        <h2 style={{ color: "#000" }}>
          Sell Your Old {location.state?.productCategory}{" "}
        </h2>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search model to sell"
            className="search-input"
            value={searchInput}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <div
            className="search-icon"
            style={{ marginLeft: "-45px", cursor: "pointer" }}
            onClick={() => handleSearch(searchInput)}
          >
            <FaSearch />
          </div>
        </div>
        {searchInput && searchData ? (
          <div
            className="sellsearch d-flex overflow-scroll position-absolute"
            style={{ height: "308px", width: "297px" }}
          >
            {searchData?.searchSellProduct &&
            searchData.searchSellProduct.length > 0 ? (
              <div className="">
                {searchData.searchSellProduct.map((product) => (
                  <div
                    key={product.productId}
                    onClick={() =>
                      handleSelect2(
                        product.productName,
                        product.id,
                        product?.productBrand
                      )
                    }
                    className="sellsearchitem"
                  >
                    {product.productName}
                  </div>
                ))}
              </div>
            ) : (
              <div>No products found</div>
            )}
          </div>
        ) : null}
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/" style={{ fontWeight: "bold", color: "#2ecc71" }}>
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span
              onClick={() => hendleSelectProduct()}
              style={{ fontWeight: "bold", color: "#2ecc71" }}
            >
              Select Brand
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item
            active
            style={{ fontWeight: "bold", color: "#7f8c8d" }}
          >
            Select Product
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <h3
        style={{
          margin: "1rem",
          fontWeight: "600",
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        {" "}
        Choose your <span className="text-success"> Model </span>
      </h3>
      <Container className="brand-container" style={{ margin: "0px" }} fluid>
        <div>
          <div className="series-list">
            {getseriesloading ? (
              <Spinner
                animation="border"
                variant="success"
                className="mx-auto d-block"
              />
            ) : (
              getSeriesByBrand &&
              getSeriesByBrand?.getSeriesByBrand.map((series) => (
                <Dropdown
                  style={{ margin: "1rem" }}
                  className="mx-auto d-block"
                  drop="down"
                  key={series.seriesName}
                >
                  <div
                    onClick={(e) => handleProductDropdown(series.seriesName)}
                  >
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="seriesBtn"
                    >
                      {series.seriesName}
                    </Dropdown.Toggle>
                  </div>

                  <Dropdown.Menu
                    className="series-dropdown-list overflow-scroll"
                    style={{ maxHeight: "300px", overflowY: "auto" }}
                    popperConfig={{
                      modifiers: [
                        {
                          name: "flip",
                          enabled: false, // Disable flipping to prevent opening in top direction
                        },
                        {
                          name: "offset",
                          options: {
                            offset: [0, 10], // Adjust the offset as needed
                          },
                        },
                      ],
                    }}
                  >
                    {productLoading ? (
                      <Spinner
                        animation="border"
                        variant="success"
                        className="mx-auto d-block"
                      />
                    ) : (
                      productBySeriesData &&
                      productBySeriesData?.getProductBySeries.map((product) => (
                        <Dropdown.Item
                          onClick={() =>
                            handleSelect(product.productName, product.id)
                          }
                          key={product.id}
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            width: "100%",
                          }}
                        >
                          {product?.productName}
                        </Dropdown.Item>
                      ))
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              ))
            )}
          </div>
        </div>
        <PhoneQuote />
      </Container>
      | <Footer />
    </>
  );
};
export default ProductBrand;
