import { useQuery } from "@apollo/client";
import { Box, useMediaQuery } from "@mui/material";
import React, { useRef, useState } from "react";
import { QUERY_GET_ALL_BRANDS_BY_CAT } from "../../../../../graphql/Query";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import {
  ArrowBackIosNew,
  ArrowForwardIos,
  KeyboardArrowRight,
} from "@mui/icons-material";
import BrandCard from "./BrandCard";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import BrandsModal from "../modals/BrandsModal";
import { getBrandName } from "../../../../../utils/getNamesFromUrls";
import { useLocation } from "react-router-dom";

const BrandCarousel = () => {
  const isSmallScreen = useMediaQuery("(max-width:766px)");
  const [brandsModalOpen, setBrandsModalOpen] = useState(false);
  const location = useLocation();
  const extracted = location.pathname
    .replace(/\/sell(-old)?-/, "")
    .replace(/-/g, " ");

  const value = getBrandName[extracted];

  const handleBrandsModalOpen = () => {
    setBrandsModalOpen(true);
  };
  const { data, loading, error, refetch } = useQuery(
    QUERY_GET_ALL_BRANDS_BY_CAT,
    {
      variables: {
        category: value,
      },
    }
  );
  console.log(data);

  const handlePrevClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const swiperRef = useRef(null);

  if (loading) {
    return <>Loading....</>;
  }

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={isSmallScreen ? "0.75rem" : "1.5rem"}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box flexBasis={"70%"}>
            <CustomTypography
              variant={"subtitle1"}
              sx={{
                fontWeight: "600",
                fontSize: { xs: "0.95rem", laptop: "1.3rem" },
              }}
            >
              Start Selling Phones By Selecting Brand.
            </CustomTypography>
          </Box>
          <Box
            flexBasis={"30%"}
            display={"flex"}
            sx={{ justifyContent: "flex-end" }}
          >
            <CustomTypography
              variant={"subtitle1"}
              onClick={handleBrandsModalOpen}
              sx={{
                fontWeight: "800",
                fontSize: { xs: "0.75rem", laptop: "1rem" },
                color: "#5B5C5D",
                textDecoration: "underline",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              More Brands{" "}
              <KeyboardArrowRight
                sx={{ fontSize: { xs: "1.25rem", laptop: "1.5rem" } }}
              />
            </CustomTypography>
          </Box>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: "100%",
              width: "100%",
            }}
          >
            <Swiper
              ref={swiperRef}
              modules={[Navigation, Pagination]}
              navigation={{
                nextEl: ".brands-swiper-button-next",
                prevEl: ".brands-swiper-button-prev",
              }}
              spaceBetween={20}
              slidesPerView={2}
              slidesPerGroup={1}
              loop={false}
              breakpoints={{
                0: {
                  slidesPerView: 3.1,
                  spaceBetween: 10,
                },
                480: {
                  slidesPerView: 3.5,
                  spaceBetween: 10,
                },
                600: {
                  slidesPerView: 4.1,
                  spaceBetween: 20,
                },
                821: {
                  slidesPerView: 5.1,
                  spaceBetween: 20,
                },
                1025: {
                  slidesPerView: 7.2,
                  spaceBetween: 25,
                },
              }}
              className="brands-cards"
              style={{
                "--swiper-navigation-color": "#000",
                "--swiper-pagination-color": "#000",
              }}
            >
              <div className="swiper-wrapper">
                {data?.getAllBrandByCategory?.map((brand) => (
                  <SwiperSlide key={brand.id}>
                    <BrandCard brand={brand} />
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
            <Box
              className="brands-swiper-controls"
              sx={{
                position: "absolute",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                top: "50%",
                transform: "translateY(-50%)",
                left: 0,
                right: 0,
                padding: "0 2rem",
              }}
            >
              <button
                className="brands-swiper-button-prev"
                aria-label="Previous"
                style={{ border: "2px solid grey", cursor: "pointer" }}
                onClick={handlePrevClick}
              >
                <ArrowBackIosNew fontSize="medium" sx={{ color: "grey" }} />
              </button>
              <button
                className="brands-swiper-button-next"
                aria-label="Next"
                style={{ border: "2px solid grey", cursor: "pointer" }}
                onClick={handleNextClick}
              >
                <ArrowForwardIos fontSize="medium" sx={{ color: "grey" }} />
              </button>
            </Box>
          </Box>
        </Box>
        <style jsx>{`
          .brands-cards {
            width: 100% !important;
            position: relative;
            padding: 1rem 0.5rem;
          }

          .brands-cards .swiper-wrapper {
            display: flex;
            width: 100%;
          }

          .brands-cards .swiper-slide {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-shrink: 0;
          }

          .brands-cards .swiper-slide .brand-card {
            transition: all 0.3s ease;
            width: 100%;
          }
          .brands-swiper-controls {
            z-index: 10;
          }

          .brands-swiper-button-next,
          .brands-swiper-button-prev {
            position: absolute;
            font-size: 1rem;
            top: 80px;
            right: 0;
            display: flex;
            border: none !important;
            background: none !important;
            transition: opacity 0.3s;
            z-index: 10;
            cursor: pointer;
          }
          .brands-swiper-button-prev {
            right: 40px;
          }

          @media (max-width: 766px) {
            .brands-cards {
              width: 100% !important;
              position: relative;
              padding: 0.5rem 0.25rem;
            }
          }

          @media (max-width: 1025px) {
            .brands-swiper-button-prev,
            .brands-swiper-button-next {
              display: none;
            }
          }
        `}</style>
      </Box>
      <BrandsModal
        brands={data?.getAllBrandByCategory}
        modalOpen={brandsModalOpen}
        setModalOpen={setBrandsModalOpen}
      />
    </>
  );
};

export default BrandCarousel;
