import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import SellingBreadCrumb from "../../shared/SellingBreadCrumb";

const generateBreadcrumbs = (pathname) => {
  const pathnames = pathname.split("/").filter((x) => x);
  const breadcrumbs = pathnames.map((value, index) => {
    const to = `/${pathnames.slice(0, index + 1).join("/")}`;
    return {
      text: value.replace(/-/g, " ").replace(/(^\w)/, (c) => c.toUpperCase()),
      to,
    };
  });
  return [{ text: "Home", to: "/" }, ...breadcrumbs];
};

const ModelHeading = () => {
  const isSmallScreen = useMediaQuery("(max-width:766px)");
  return (
    <Box
      sx={{ width: "100%" }}
      display={"flex"}
      flexDirection={"column"}
      gap={"1rem"}
    >
      <Box>
        <CustomTypography
          varaint={"subtitle1"}
          sx={{
            fontWeight: "600",
            fontSize: isSmallScreen ? "1.25rem" : "1.5rem",
          }}
        >
          Sell Your Old Phones
        </CustomTypography>
      </Box>
      <Box>
        <CustomTypography>
          <SellingBreadCrumb generateBreadcrumbs={generateBreadcrumbs} />
        </CustomTypography>
      </Box>
    </Box>
  );
};

export default ModelHeading;
