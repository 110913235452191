import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import { QUERY_GET_SELL_PRODUCT } from "../../graphql/Query";
import {
  DELETE_SELLING_PRODUCT_IMAGE,
  MUTATION_DELETE_SELL_PRODUCT,
  MUTATION_SELL_PRODUCT,
  UPDATE_SELLING_PRODUCT_IMAGE,
} from "../../graphql/Mutations";
import swal from "sweetalert";
import { FaEye, FaPencilAlt } from "react-icons/fa";

import "../../ComponentsCss/AdminCss/AllsellingProduct.css";
import EditModal from "./Modals/EditSellingProductModal";
import EditImage from "./Modals/editImage";
import { Box, CircularProgress } from "@mui/material";
import { cartPageBackdrop } from "../../store/atoms/addToCart/addToCart";
import { useSetRecoilState } from "recoil";
import { FileUpload } from "@mui/icons-material";
import MuiBackdrop from "../../muicomponents/shared/MuiBackdrop";
import CustomTypography from "../../muicomponents/shared/CustomTypography";
import VariantEditingModal from "./Modals/VariantEditingModal";
const AllSellingProducts = () => {
  const { data: sellProData, loading: sellProLoading } = useQuery(
    QUERY_GET_SELL_PRODUCT
  );
  const [productFileData, setProductFileData] = useState({});
  const [selectedProduct, setSelectedProduct] = useState(null);
  let count = 1;
  const [smShow, setSmShow] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [imagemodal, setImageModal] = useState(false);
  const [proId, setProId] = useState();
  const [variantData, setVariantData] = useState(null);

  // console.log(sellProData);

  const [showVariantModal, setShowVariantModal] = useState(false);
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);

  const getImageName = (invoiceLink) => {
    // console.log(invoiceLink);
    if (!invoiceLink) return "";
    const parts = invoiceLink.split("/");
    // console.log(parts[parts.length - 1]);
    return parts[parts.length - 1];
  };

  const [uploadModelImage, { loading: uploadingImageLoading }] = useMutation(
    UPDATE_SELLING_PRODUCT_IMAGE,
    {
      refetchQueries: [QUERY_GET_SELL_PRODUCT],
    }
  );
  const [deleteSellingImage, { loading: deleteImageLoading }] = useMutation(
    DELETE_SELLING_PRODUCT_IMAGE,
    {
      refetchQueries: [QUERY_GET_SELL_PRODUCT],
    }
  );

  const [deleteSellProductByid, { loading: dltLoading }] = useMutation(
    MUTATION_DELETE_SELL_PRODUCT,
    {
      refetchQueries: [QUERY_GET_SELL_PRODUCT],
    }
  );

  const handleOpenVariant = (variantData) => {
    setShowVariantModal(true);
    setVariantData(variantData);
  };

  const handleFileChange = (event, productId) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;

    setProductFileData((prevData) => ({
      ...prevData,
      [productId]: {
        file: selectedFile,
        fileName: selectedFile.name,
        uploadStatus: "idle",
        errorMessage: "",
      },
    }));
  };

  const handleImageDelete = async (productId) => {
    try {
      setBackdropOpen(true);
      const { data } = await deleteSellingImage({
        variables: {
          sellingProductId: productId,
        },
      });
      swal({
        title: "Success",
        text: data.deleteSellingImage.message,
        icon: "success",
      });
    } catch (error) {
      console.error("Error uploading image:", error);
      swal({
        title: "Error!!!",
        text: "Error Deleting Image",
        icon: "error",
      });
    } finally {
      setBackdropOpen(false);
    }
  };

  const handleImageUpload = async (productId) => {
    const productData = productFileData[productId];
    if (!productData || !productData.file) return;

    const formData = new FormData();
    formData.append("file", productData.file);

    try {
      setBackdropOpen(true);
      setProductFileData((prevData) => ({
        ...prevData,
        [productId]: { ...productData, uploadStatus: "uploading" },
      }));

      const { data } = await uploadModelImage({
        variables: {
          sellingProductId: productId,
          file: formData.get("file"),
        },
      });

      swal({
        title: "Success",
        text: data.uploadModelImage.message,
        icon: "success",
      });

      setProductFileData((prevData) => ({
        ...prevData,
        [productId]: {
          ...productData,
          file: null,
          fileName: "",
          uploadStatus: "success",
          errorMessage: "",
        },
      }));
    } catch (error) {
      console.error("Error uploading image:", error);
      swal({
        title: "Error!!!",
        text: "Error uploading Image",
        icon: "error",
      });
      setProductFileData((prevData) => ({
        ...prevData,
        [productId]: {
          ...productData,
          uploadStatus: "error",
          errorMessage: "Error uploading image",
        },
      }));
    } finally {
      setBackdropOpen(false);
    }
  };

  function showdlthandle(id) {
    setProId(id);
    setSmShow(true);
  }
  async function deleteHandle() {
    await deleteSellProductByid({
      variables: {
        sellProductId: `${proId}`,
      },
    });
    swal({
      title: "Deleted",
      text: "Product Delete successfully",
      icon: "success",
    });
    setSmShow(false);
  }
  const handleEdit = (product) => {
    setEditModal(true);
    setSelectedProduct(product);
  };
  const handleImageEdit = (pro) => {
    setImageModal(true);
    setSelectedProduct(pro);
  };
  return (
    <>
      <MuiBackdrop />
      <Table responsive striped bordered hover>
        <thead className="table-head">
          <tr>
            <th>s.no.</th>
            <th>Produc Name</th>
            <th>Produc Price</th>

            {/* <th>Product Description</th> */}
            <th>Brand</th>
            <th>Year</th>
            <th>Variants</th>
            <th>Series</th>
            {/* <th>Product Type</th> */}
            <th>Category</th>
            {/* <th>Image</th> */}
            <th>Image</th>
            <th>Remove Product</th>
            <th>Edit Product</th>
          </tr>
        </thead>
        <tbody>
          {sellProLoading ? (
            <Spinner
              animation="border"
              variant="success"
              className="mx-auto d-block"
            />
          ) : (
            sellProData &&
            sellProData.getSellProduct
              .slice(0)
              .reverse()
              .map((data) => (
                <tr className="table-data" key={data.id}>
                  <td>{count++}</td>
                  <td>{data.productName}</td>
                  <td>
                    {data.productPrice === null ? "N/A" : data.productPrice}
                  </td>
                  {/* <td>{data.productDescription}</td> */}
                  <td>{data.productBrand}</td>
                  <td>
                    {data.releasedYear === null ? "N/A" : data?.releasedYear}
                  </td>
                  <td>
                    {data.hasVariants ? (
                      <FaEye
                        onClick={() => handleOpenVariant(data)}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td>{data.series}</td>
                  <td>{data.category}</td>
                  <td>
                    {data.image !== "undefined" && data.image !== null ? (
                      <Box display="flex" flexDirection="column" gap="0.5rem">
                        {getImageName(data.image)}
                        <FaEye
                          style={{ cursor: "pointer", fontSize: "1rem" }}
                          onClick={() =>
                            window.open(
                              `${`https://hellofi.s3.ap-south-1.amazonaws.com/`}${
                                data.image
                              }`,
                              "_blank"
                            )
                          }
                        />
                        <button
                          style={{
                            color: "#dc3545",
                            borderColor: "#dc3545",
                          }}
                          onClick={() => handleImageDelete(data.id)}
                        >
                          delete
                        </button>
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        gap="0.5rem"
                      >
                        <input
                          type="file"
                          id={`file-input-${data.id}`}
                          onChange={(event) => handleFileChange(event, data.id)}
                          style={{ display: "none" }}
                          accept=".png, .jpg, .jpeg, .gif, .bmp, .webp"
                        />
                        <Button
                          onClick={() =>
                            document
                              .getElementById(`file-input-${data.id}`)
                              .click()
                          }
                          startIcon={<FileUpload />}
                        >
                          <span style={{ fontSize: "0.75rem" }}>
                            {uploadingImageLoading
                              ? "Uploading..."
                              : "Add Image"}
                          </span>
                        </Button>

                        {productFileData[data.id]?.fileName && (
                          <Box>
                            {productFileData[data.id].fileName.length > 20
                              ? productFileData[data.id].fileName.substring(
                                  0,
                                  20
                                ) + "..."
                              : productFileData[data.id].fileName}
                          </Box>
                        )}

                        {productFileData[data.id]?.uploadStatus === "error" && (
                          <Box>{productFileData[data.id].errorMessage}</Box>
                        )}

                        {productFileData[data.id]?.file && (
                          <button
                            onClick={() => handleImageUpload(data.id)}
                            disabled={
                              productFileData[data.id].uploadStatus ===
                              "uploading"
                            }
                          >
                            {productFileData[data.id].uploadStatus ===
                            "uploading" ? (
                              <CircularProgress size={16} color="inherit" />
                            ) : (
                              "Submit"
                            )}
                          </button>
                        )}
                      </Box>
                    )}
                  </td>
                  <td>
                    <Button
                      className="mx-auto d-block"
                      variant="danger"
                      onClick={() => showdlthandle(data.id)}
                    >
                      -
                    </Button>
                  </td>
                  <td>
                    {" "}
                    <Button
                      className="mx-auto d-block"
                      onClick={() => handleEdit(data)}
                    >
                      {" "}
                      Edit{" "}
                    </Button>
                  </td>
                </tr>
              ))
          )}
        </tbody>
      </Table>
      {imagemodal ? (
        <EditImage
          data={selectedProduct}
          imagemodal={imagemodal}
          setImageModal={setImageModal}
        />
      ) : (
        ""
      )}
      {editModal ? (
        <EditModal
          data={selectedProduct}
          setEditModal={setEditModal}
          editModal={editModal}
        />
      ) : (
        ""
      )}
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body style={{ textAlign: "center" }}>
          <h5>Are you sure you want to delete this Product?</h5>
          {dltLoading ? (
            <Spinner animation="border" variant="danger" />
          ) : (
            <>
              <Button
                onClick={deleteHandle}
                style={{
                  backgroundColor: "#40a737",
                  border: "none",
                  width: "100%",
                }}
              >
                Yes
              </Button>
              <Button
                onClick={() => setSmShow(false)}
                style={{
                  marginTop: "1rem",
                  backgroundColor: "lightcoral",
                  border: "none",
                  width: "100%",
                }}
              >
                No
              </Button>
            </>
          )}
        </Modal.Body>
      </Modal>
      <VariantEditingModal
        modalOpen={showVariantModal}
        setModalOpen={setShowVariantModal}
        variantData={variantData}
      />
    </>
  );
};

export default AllSellingProducts;
