import React from "react";
import SellingBrands from "../components/sellingBrands/SellingBrands";

const SellingBrandsPage = () => {
  return (
    <>
      <SellingBrands />
    </>
  );
};

export default SellingBrandsPage;
