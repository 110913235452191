import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import React from "react";
import { QUERY_GET_SERIES_BY_BRAND } from "../../../../../graphql/Query";
import SeriesItem from "./SeriesItem";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const SeriesFilter = () => {
  const { data, loading, error } = useQuery(QUERY_GET_SERIES_BY_BRAND, {
    variables: { productBrand: "Apple(Phones)" },
  });
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{
        padding: "1.5rem",
        gap: "1rem",
        width: "100%",
        borderRadius: "0.5rem",
        position: "sticky",
        height: "calc(100vh - 110px)",
        overflowY: "scroll",
        top: "95px",
        boxShadow:
          "0 7px 15px 0 rgba(0, 0, 0, .13), 0 1px 4px 0 rgba(0, 0, 0, .11)",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#888",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      }}
    >
      <Box>
        <Box
          sx={{
            padding: "1rem",
            backgroundColor: "#E0E9F1",
            borderRadius: "0.5rem",
          }}
        >
          <CustomTypography variant={"subtitle2"}>All</CustomTypography>
        </Box>
        {data?.getSeriesByBrand?.map((series) => (
          <SeriesItem series={series} />
        ))}
      </Box>
    </Box>
  );
};

export default SeriesFilter;
