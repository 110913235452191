import { Box } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const NeedHelpCard = ({ img, width, height, title, subtitle }) => {
  return (
    <Box
      sx={{
        padding: "1rem",
        background:
          "linear-gradient(180deg, rgba(125, 190, 197, 0.5) 0%, rgba(255, 255, 255, 0.8) 100%)",
        minHeight: "150px",
        width: "100%",
        height: "250px",
        borderRadius: "0.5rem",
        display: "flex",
        flexDirection: "column",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        "&:hover": {
          cursor: "pointer",
          transform: "scale(1.01)",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        },
      }}
      className={"help-card"}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ width: "100%", height: "100%" }}
      >
        <img src={img} style={{ width: width, height: height }} />
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <CustomTypography variant={"subtitle1"} sx={{ fontWeight: "600" }}>
          {title}
        </CustomTypography>
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "300" }}>
          {subtitle}
        </CustomTypography>
      </Box>
    </Box>
  );
};

export default NeedHelpCard;
