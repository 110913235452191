import * as React from "react";
import AssuredCard from "../../muiComponents/AssuredCard";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import DiscountFunction from "../../../../utils/DiscountFunction";
import "../../css/style.css";
import { useNavigate } from "react-router-dom";

export default function RelatedProductCard({ product, isBuy }) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery("(max-width:640px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("desktop"));
  const navigate = useNavigate();

  // console.log(product);
  const handleProductClick = (product) => {
    if (isBuy) {
      navigate(`/buying-product-details/${product.id}`);
    } else if (product.productCategory === "Desktop/Imac") {
      navigate(`/buying-products/Desktop-Imac`, {
        state: {
          product: product,
        },
      });
    } else {
      navigate(`/buying-products/${product.productCategory}`, {
        state: {
          product: product,
        },
      });
    }
  };
  return (
    <Box
      className={"brandcard"}
      onClick={() => handleProductClick(product)}
      display={"flex"}
      flexDirection={"column"}
      sx={{
        maxWidth: isMobileScreen ? "100%" : isSmallScreen ? "100%" : "100%",
        flex: "1 0 auto",
        position: "relative",
        height: "100%",
        borderRadius: "0.5rem",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        margin: !isSmallScreen && "1.03rem",
        "&:hover": {
          cursor: "pointer",
          zIndex: "1",
        },
      }}
    >
      <Box className={isSmallScreen ? "" : "overlaycard"}></Box>
      <Box
        position={"absolute"}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "2rem",
          width: "60px",
          height: "60px",
          borderRadius: "50%",
          top: "-16px",
          left: "-16px",
          backgroundColor: "#fd5c63",
        }}
      >
        <CustomTypography
          sx={{
            color: "white",
            fontWeight: "700",
            fontSize: "12px",
            lineHeight: "1",
          }}
        >
          {isBuy
            ? product.condition === "Partially-Fair"
              ? "P-Fair"
              : product.condition
            : "SALE"}
        </CustomTypography>
        <CustomTypography
          sx={{
            color: "white",
            fontWeight: "700",
            fontSize: "12px",
            lineHeight: "1",
          }}
        >
          condition
        </CustomTypography>
      </Box>
      <Box
        position={"absolute"}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "2rem",
          width: "60px",
          height: "60px",
          borderRadius: "50%",
          top: "-16px",
          right: "-16px",
          backgroundColor: "#fd5c63",
        }}
      >
        <CustomTypography
          sx={{
            color: "white",
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "1",
          }}
        >
          {DiscountFunction(product.productPrice, product.mrp)}%
        </CustomTypography>
        <CustomTypography
          sx={{
            color: "white",
            fontWeight: "700",
            fontSize: "14px",
            lineHeight: "1",
          }}
        >
          OFF
        </CustomTypography>
      </Box>
      <Box>
        <img
          src={`${`https://hellofi.s3.ap-south-1.amazonaws.com/`}${
            product?.images[0]?.md
          }`}
          alt=""
          style={{
            objectFit: "cover",
            width: "100%",
            height: isMobileScreen ? "200px" : "280px",
            borderRadius: "0.5rem",
          }}
        />
      </Box>
      <Box padding={"0 0.4rem"}>
        <CustomTypography
          sx={{
            fontWeight: "600",
            fontSize: "0.9rem",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
            textOverflow: "ellipsis",
            lineHeight: "1.2rem",
            height: "3.6rem",
          }}
        >
          {product.productName}
        </CustomTypography>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        padding={"1rem"}
        alignItems={"center"}
        gap={"0.5rem"}
        sx={{
          backgroundColor: "#378b29",
          color: "white",
          borderBottomLeftRadius: "0.5rem",
          borderBottomRightRadius: "0.5rem",
        }}
      >
        <Box>
          <CustomTypography sx={{ fontSize: "1.2rem", fontWeight: "700" }}>
            ₹{product.productPrice}
          </CustomTypography>
        </Box>
        <Box>
          <CustomTypography
            sx={{
              fontSize: "0.8rem",
              fontWeight: "400",
              textDecoration: "line-through",
            }}
          >
            ₹{product.mrp}
          </CustomTypography>
        </Box>
      </Box>
      {!isSmallScreen &&
        (isBuy ? (
          <Box className={isSmallScreen ? "" : "view"}>View</Box>
        ) : (
          <Box className={isSmallScreen ? "" : "view"}>View All</Box>
        ))}
    </Box>
  );
}
