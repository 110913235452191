import { Breadcrumbs, Link, Box } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import { useLocation, Link as RouterLink } from "react-router-dom";

const SellingBreadCrumb = ({ generateBreadcrumbs }) => {
  const location = useLocation();
  const breadcrumbs = generateBreadcrumbs(location.pathname);
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      {breadcrumbs.map((breadcrumb, index) =>
        index === breadcrumbs.length - 1 ? (
          <CustomTypography
            key={breadcrumb.to}
            color="text.primary"
            sx={{
              fontSize: { xs: "0.875rem", laptop: "1rem" },
              textTransform: "capitalize",
            }}
          >
            {breadcrumb.text}
          </CustomTypography>
        ) : (
          <Link
            key={breadcrumb.to}
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={breadcrumb.to}
          >
            <CustomTypography
              sx={{
                fontSize: { xs: "0.875rem", laptop: "1rem" },
                textTransform: "capitalize",
              }}
            >
              {breadcrumb.text}
            </CustomTypography>
          </Link>
        )
      )}
    </Breadcrumbs>
  );
};

export default SellingBreadCrumb;
