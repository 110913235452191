import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import BrandsBackgroundLaptop from "../../../../../Images/selling/sellingBrandsPage/BrandsBackgroundLaptop.png";
import BrandsBackgroundMobile from "../../../../../Images/selling/sellingBrandsPage/BrandsBackgroundMobile.png";
import SellingInput from "./SellingInput";
import SellingTextarea from "./SellingTextarea";

const SellingForm = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:766px)");
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={isSmallScreen ? "1rem" : "3rem"}
      marginTop={isSmallScreen ? "0rem" : "4rem"}
    >
      <Box
        sx={{
          backgroundColor: isSmallScreen ? "#E0E9F1" : "white",
          padding: "0.5rem",
          borderRadius: "0.5rem",
        }}
      >
        <CustomTypography
          variant={"h6"}
          sx={{
            fontWeight: "600",
            fontSize: { xs: "0.95rem", laptop: "1.3rem" },
          }}
        >
          If your Product is not listed with us you can still Request a Quote.
        </CustomTypography>
      </Box>
      <Box
        sx={{
          minHeight: "400px",
          width: "100%",
          position: "relative",
          backgroundPosition: "center",
          backgroundColor: "white",
          backgroundImage: `url(${BrandsBackgroundLaptop})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          borderRadius: "1rem",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          opacity: 0.75,
          padding: { xs: "1rem", laptop: "3rem" },
        }}
      >
        <Grid container columnSpacing={5} rowSpacing={2}>
          <Grid item xs={12} laptop={6}>
            <SellingInput label={"Name"} placeHolder={"Enter Your Name"} />
          </Grid>
          <Grid item xs={12} laptop={6}>
            <SellingInput label={"Email"} placeHolder={"Enter Your Email"} />
          </Grid>
          <Grid item xs={12} laptop={6}>
            <SellingInput
              label={"Phone"}
              placeHolder={"Enter Your Phone Number"}
            />
          </Grid>
          <Grid item xs={12} laptop={6}>
            <SellingInput
              label={"Product Type"}
              placeHolder={"Ex: Phones, Laptops, Tablets"}
            />
          </Grid>
          <Grid item xs={12} laptop={6}>
            <SellingInput
              label={"Brand Of Your Product"}
              placeHolder={"Ex: Apple, Samsung, Oneplus"}
            />
          </Grid>
          <Grid item xs={12} laptop={6}>
            <SellingInput
              label={"Model"}
              placeHolder={"Paste exact online link of your product"}
            />
          </Grid>
          <Grid item xs={12} laptop={6}>
            <SellingInput
              label={"Storage"}
              placeHolder={"Ex: 32GB, 64GB, 128GB."}
            />
          </Grid>
          <Grid item xs={12} laptop={6}>
            <SellingInput label={"RAM"} placeHolder={"Ex: 8GB, 16GB."} />
          </Grid>
          <Grid item xs={12} laptop={6}>
            <SellingInput
              label={"Age Of Your Product"}
              placeHolder={"Select Age"}
            />
          </Grid>
          <Grid item xs={12} laptop={6}>
            <SellingInput
              label={"Physical Condition"}
              placeHolder={"Select Condition"}
            />
          </Grid>
          <Grid item xs={12} laptop={6}>
            <SellingInput
              label={"Accessories included"}
              placeHolder={"Ex: Box, Charger, Cable etc."}
            />
          </Grid>
          <Grid item xs={12} laptop={6}>
            <SellingInput label={"Original invoice *"} placeHolder={"Select"} />
          </Grid>
          <Grid item xs={12}>
            <SellingTextarea
              label={
                "Tell us about the device( Additional incase of any defect / problem in it )"
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SellingForm;
