import { Box, Grid } from "@mui/material";
import React, { useRef } from "react";
import Whatsapp from "../../../../../Images/home/needHelp/Whatsapp.png";
import Gmail from "../../../../../Images/home/needHelp/Gmail.png";
import Questions from "../../../../../Images/home/needHelp/Questions.png";
import NeedHelpCard from "./NeedHelpCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";

const AllNeedHelps = () => {
  const handlePrevClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const swiperRef = useRef(null);
  const data = [
    {
      img: Whatsapp,
      width: "120px",
      height: "120px",
      title: "Ask Us On Whatsapp!!",
      subtitle: "Get instant support via expert",
    },
    { img: Gmail, width: "140px", height: "140px", title: "Mail Us" },
    { img: Questions, width: "180px", height: "140px", title: "FAQs" },
  ];
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          position: "relative",
          height: "100%",
          width: "100%",
        }}
      >
        <Swiper
          ref={swiperRef}
          modules={[Pagination]}
          spaceBetween={20}
          slidesPerView={2}
          slidesPerGroup={1}
          loop={false}
          breakpoints={{
            0: {
              slidesPerView: 1.2,
              spaceBetween: 10,
            },
            600: {
              slidesPerView: 2.2,
              spaceBetween: 20,
            },
            821: {
              slidesPerView: 2.5,
              spaceBetween: 20,
            },
            1025: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
          }}
          className="help-cards"
        >
          <div className="swiper-wrapper">
            {data.map((blog, index) => (
              <SwiperSlide key={index}>
                <NeedHelpCard
                  img={blog.img}
                  width={blog.width}
                  title={blog.title}
                  subtitle={blog.subtitle}
                />
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </Box>
      <style jsx>{`
        .help-cards {
          width: 100% !important;
          position: relative;
          padding: 0.5rem;
        }

        .help-cards .swiper-wrapper {
          display: flex;
          width: 100%;
        }

        .help-cards .swiper-slide {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-shrink: 0;
        }

        .help-cards .swiper-slide .help-card {
          transition: all 0.3s ease;
          width: 100%;
        }

        .help-swiper-button-next,
        .help-swiper-button-prev {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-color: rgba(255, 255, 255, 0.8);
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid grey;
          transition: opacity 0.3s;
          z-index: 10;
          cursor: pointer;
        }

        .help-swiper-button-prev {
          left: -25px;
        }

        .help-swiper-button-next {
          right: -25px;
        }

        @media (max-width: 1025px) {
          .help-swiper-button-prev,
          .help-swiper-button-next {
            display: none;
          }
        }
      `}</style>
    </Box>
  );
};

export default AllNeedHelps;
