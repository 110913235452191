import React from "react";
import logo from "../../Images/homepage/logo.png";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Alarm, Email, Person, ShoppingCart } from "@mui/icons-material";

const Header = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  const isTablet = useMediaQuery(
    theme.breakpoints.between("tablet", "tabletLarge")
  );
  const isLaptop = useMediaQuery(
    theme.breakpoints.between("laptop", "extraLarge")
  );
  const isDesktop = useMediaQuery(theme.breakpoints.up("extraLarge"));

  const imageSize = {
    width: isMobile
      ? "100px"
      : isTablet
      ? "120px"
      : isLaptop
      ? "150px"
      : isDesktop
      ? "180px"
      : "150px",
    height: isMobile
      ? "30px"
      : isTablet
      ? "40px"
      : isLaptop
      ? "50px"
      : isDesktop
      ? "60px"
      : "50px",
  };

  const margin = isMobile
    ? "0 0.5rem"
    : isTablet
    ? "0 0.75rem"
    : isLaptop
    ? "0 2rem"
    : "0 auto";
  return (
    <Box
      display={"flex"}
      sx={{
        position: "sticky",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 100,
        backgroundColor: "#214C7B",
        height: isMobile ? "65px" : "80px",
        margin: "0 auto",
        minWidth: "300px",
      }}
    >
      <Box
        sx={{
          maxWidth: "1280px",
          margin: "0 auto",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ margin: margin, width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                borderRadius: "0.5rem",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                padding: "0.25rem 1rem",
              }}
            >
              <img
                src={logo}
                style={{
                  width: imageSize.width,
                  height: imageSize.height,
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderRadius: "0.5rem",
                justifyContent: "center",
                alignItems: "center",
                gap: isMobile ? "0.5rem" : "1.5rem",
              }}
            >
              <Box
                sx={{
                  padding: isMobile ? "0.45rem" : "0.5rem 1rem",
                  backgroundColor: "white",
                  borderRadius: "0.5rem",
                }}
              >
                <Email />
              </Box>
              <Box
                sx={{
                  padding: isMobile ? "0.45rem" : "0.5rem 1rem",
                  backgroundColor: "white",
                  borderRadius: "0.5rem",
                }}
              >
                <ShoppingCart />
              </Box>
              <Box
                sx={{
                  padding: isMobile ? "0.45rem" : "0.5rem 1rem",
                  backgroundColor: "white",
                  borderRadius: "0.5rem",
                }}
              >
                <Person />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
