import { Box } from "@mui/material";
import React from "react";
import AllBlogs from "./AllBlogs";
import HomePageContainer from "../shared/HomePageContainer";
import Heading from "../shared/Heading";

const Blogs = () => {
  return (
    <HomePageContainer>
      <Heading text={"Blogs"} sx={{ margin: "0 0.5rem" }} />
      <AllBlogs />
    </HomePageContainer>
  );
};

export default Blogs;
