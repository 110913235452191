import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const BlogCard = ({ description, title, image }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <Box
      className={"blogs-card"}
      sx={{
        background:
          "linear-gradient(180deg, rgba(94, 92, 92, 0.4) 0%, rgba(255, 255, 255, 0.4) 79%)",
        borderRadius: "1rem",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
        width: "100%",
        minHeight: "350px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          padding: "1.5rem 1.5rem 0 1.5rem",
        }}
      >
        <img
          src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${image}`}
          style={{
            width: "100%",
            height: isMobileScreen ? "175px" : "200px",
            borderRadius: "0.5rem",
          }}
        />
      </Box>
      <Box
        sx={{ padding: "0 1.5rem" }}
        display={"flex"}
        flexDirection={"column"}
        gap={"1rem"}
      >
        <CustomTypography
          variant={"subtitle1"}
          sx={{
            fontWeight: "600",
            lineHeight: 1.4,
            fontSize: {
              xs: "0.95rem",
              desktop: "1rem",
            },
          }}
        >
          {title}
        </CustomTypography>
        <CustomTypography
          variant={"subtitle2"}
          sx={{
            fontSize: {
              xs: "0.875rem",
              desktop: "0.90rem",
            },
            lineHeight: 1.35,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 6,
          }}
        >
          {description}
        </CustomTypography>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        sx={{ padding: "1rem 1.5rem 1.5rem 1.5rem" }}
      >
        <Button
          sx={{
            borderRadius: "0.2rem",
            padding: "0.25rem",
            textTransform: "none",
            backgroundColor: "#214C7B",
            boxShadow: "none",
            color: "white",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "#214C7B",
            },
          }}
        >
          <CustomTypography variant={"subtitle2"}>See more</CustomTypography>
        </Button>
      </Box>
    </Box>
  );
};

export default BlogCard;
