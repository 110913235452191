import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const SellingInput = ({ label, placeHolder }) => {
  const isSmallScreen = useMediaQuery("(max-width:766px)");
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"0.5rem"}>
      <Box>
        <CustomTypography
          variant={"subtitle1"}
          sx={{
            color: "#214C7B",
            fontWeight: "600",
            fontSize: isSmallScreen ? "0.9rem" : "1rem",
          }}
        >
          {label}
        </CustomTypography>
      </Box>
      <Box>
        <input
          placeholder={placeHolder}
          style={{
            borderRadius: "0.5rem",
            border: "0.5px solid black",
            padding: "0.5rem",
            width: "100%",
          }}
        />
      </Box>
    </Box>
  );
};

export default SellingInput;
