import {
  Modal,
  Box,
  Button,
  useMediaQuery,
  useTheme,
  TextField,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Close, Delete, Edit, Add, Cancel } from "@mui/icons-material";
import CustomTypography from "../../../muicomponents/shared/CustomTypography";
import { useMutation } from "@apollo/client";
import swal from "sweetalert";
import useSnackbarNotifier from "../../../features/shared/hooks/useSnackbarNotifier";
import {
  ADD_VARIANT,
  DELETE_VARIANT,
  UPDATE_CONSTANT_RAM,
  UPDATE_VARIANT,
} from "../../../graphql/Mutations";
import { QUERY_GET_SELL_PRODUCT } from "../../../graphql/Query";

const VariantEditingModal = ({ modalOpen, setModalOpen, variantData }) => {
  const showSnackbar = useSnackbarNotifier();
  const [ram, setRam] = useState("");
  const [editingConstantRam, setEditingConstantRam] = useState(false);
  const [editingVariant, setEditingVariant] = useState(null);
  const [editedVariants, setEditedVariants] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);

  const [addSellingProductVariant] = useMutation(ADD_VARIANT, {
    refetchQueries: [QUERY_GET_SELL_PRODUCT],
  });
  const [updateConstantRam] = useMutation(UPDATE_CONSTANT_RAM, {
    refetchQueries: [QUERY_GET_SELL_PRODUCT],
  });

  const [updateVariant] = useMutation(UPDATE_VARIANT, {
    refetchQueries: [QUERY_GET_SELL_PRODUCT],
  });

  const [deleteVariant] = useMutation(DELETE_VARIANT, {
    refetchQueries: [QUERY_GET_SELL_PRODUCT],
  });

  const [newVariant, setNewVariant] = useState({
    ram: "",
    storage: "",
    productPrice: "",
  });

  useEffect(() => {
    if (variantData?.variants) {
      setEditedVariants(variantData.variants);
      setRam(variantData.ram);
    }
  }, [variantData, modalOpen]);

  const handleModalClose = () => {
    setModalOpen(false);
    setEditingVariant(null);
    setEditedVariants([]);
    setRam("");
    setEditingConstantRam(false);
    setShowAddForm(false);
  };

  const handleEdit = (variantIndex) => {
    setEditingVariant(variantIndex);
  };

  const handleConstantRamUpdate = async () => {
    try {
      const response = await updateConstantRam({
        variables: {
          id: variantData.id,
          constantRam: ram,
        },
      });
      const { success, message } = response.data.updateConstantRam;
      if (success) {
        swal({
          title: "Success",
          text: message,
          icon: "success",
        });
      }
    } catch (error) {
    } finally {
      setEditingConstantRam(false);
    }
  };

  const handleUpdate = async (variantIndex) => {
    const editedVariant = editedVariants[variantIndex];
    try {
      const variantData = {
        ram: editedVariant.ram,
        storage: editedVariant.storage,
        productPrice: parseInt(editedVariant.productPrice),
      };
      const response = await updateVariant({
        variables: {
          isConstantRam: variantData.isConstantRam,
          variantId: editedVariant.id,
          variantData: variantData,
        },
      });
      const { success, message, variant } = response.data.updateVariant;
      if (success) {
        setEditedVariants((prevVariants) => {
          const updatedVariants = [...prevVariants];
          updatedVariants[variantIndex] = variant;
          return updatedVariants;
        });
        swal({
          title: "Success",
          text: message,
          icon: "success",
        });
      }
      setEditingVariant(null);
    } catch (error) {
      console.error("Update error:", error);
    }
  };

  const handleDelete = async (variantId) => {
    try {
      await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this variant!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          const response = await deleteVariant({
            variables: {
              id: variantData.id,
              variantId: variantId,
            },
          });
          const { success, message } = response.data.deleteVariant;
          if (success) {
            setEditedVariants((prevVariants) =>
              prevVariants.filter((variant) => variant.id !== variantId)
            );
            swal({
              title: "Success",
              text: message,
              icon: "success",
            });
          }
        }
      });
    } catch (error) {
      console.error("Delete error:", error);
    }
  };

  const handleInputChange = (variantIndex, field, value) => {
    setEditedVariants((prevVariants) => {
      const newVariants = [...prevVariants];
      newVariants[variantIndex] = {
        ...newVariants[variantIndex],
        [field]: value,
      };
      return newVariants;
    });
  };

  const handleAddVariant = async () => {
    try {
      if (variantData.isConstantRam) {
        delete newVariant.ram;
      }

      const response = await addSellingProductVariant({
        variables: {
          id: variantData.id,
          variantData: newVariant,
        },
      });

      const { success, message, variant } =
        response.data.addSellingProductVariant;
      if (success) {
        setEditedVariants((prevVariants) => [...prevVariants, variant]);
        swal({
          title: "Success",
          text: message,
          icon: "success",
        });
      }
    } catch (error) {
      console.error("Add variant error:", error);
    } finally {
      setNewVariant({
        ram: "",
        storage: "",
        productPrice: "",
      });
      setShowAddForm(false);
    }
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));

  const renderVariantRow = (variant, index) => (
    <Box
      key={index}
      display={"flex"}
      sx={{ padding: "0 1rem 1rem 1rem", gap: "0.5rem" }}
    >
      {!variantData?.isConstantRam && (
        <Box flexBasis={"25%"}>
          <TextField
            size="small"
            disabled={editingVariant !== index}
            value={variant.ram}
            onChange={(e) => handleInputChange(index, "ram", e.target.value)}
          />
        </Box>
      )}

      <Box flexBasis={"25%"}>
        <TextField
          size="small"
          disabled={editingVariant !== index}
          value={variant.storage}
          onChange={(e) => handleInputChange(index, "storage", e.target.value)}
        />
      </Box>
      <Box
        flexBasis={"5%"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CustomTypography variant="subtitle2">-</CustomTypography>
      </Box>
      <Box flexBasis={"25%"}>
        <TextField
          size="small"
          disabled={editingVariant !== index}
          value={variant.productPrice}
          onChange={(e) =>
            handleInputChange(index, "productPrice", e.target.value)
          }
        />
      </Box>
      <Box
        flexBasis={"20%"}
        sx={{ display: "flex", alignItems: "center", gap: "0.25rem" }}
      >
        {editingVariant === index ? (
          <Button
            onClick={() => handleUpdate(index)}
            sx={{
              color: "white",
              backgroundColor: "rgb(6, 155, 170)",
              borderRadius: "3rem",
              display: "flex",
              width: "100%",
              alignItems: "center",
              padding: "0.5rem 1rem",
              whiteSpace: "nowrap",
              "&.Mui-disabled": {
                backgroundColor: "rgb(254, 123, 121)",
                color: "white",
              },
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                border: "1px solid rgb(6, 155, 170)",
              },
            }}
          >
            Update
          </Button>
        ) : (
          <IconButton onClick={() => handleEdit(index)}>
            <Edit />
          </IconButton>
        )}
        <IconButton onClick={() => handleDelete(variant.id)}>
          <Delete />
        </IconButton>
        <IconButton>
          <Cancel
            sx={{ color: "#fd5c63" }}
            onClick={() => setEditingVariant(null)}
          />
        </IconButton>
      </Box>
    </Box>
  );

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isSmallScreen ? "100%" : "60%",
          minHeight: isSmallScreen ? "100vh" : "60vh",
          maxHeight: isSmallScreen ? "100%" : "80vh",
          backgroundColor: "white",
          borderRadius: isSmallScreen ? "0px" : "8px",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            padding: "20px",
            borderBottom: "1px solid black",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <CustomTypography variant={"subtitle1"} sx={{ fontWeight: "600" }}>
            Variants
          </CustomTypography>
          <Close
            sx={{
              cursor: "pointer",
            }}
            onClick={handleModalClose}
          />
        </Box>
        <Box sx={{ height: "100%", overflowY: "scroll" }}>
          <Box
            sx={{
              padding: "1rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "500" }}>
              {variantData?.productName}
            </CustomTypography>
            <Button
              startIcon={<Add />}
              onClick={() => setShowAddForm(!showAddForm)}
              sx={{
                color: "white",
                backgroundColor: "rgb(6, 155, 170)",
                "&:hover": {
                  backgroundColor: "rgb(5, 135, 150)",
                },
              }}
            >
              Add Variant
            </Button>
          </Box>

          {showAddForm && (
            <Box
              display={"flex"}
              sx={{ padding: "0 1rem 1rem 1rem", gap: "0.5rem" }}
            >
              {!variantData.isConstantRam && (
                <Box flexBasis={"25%"}>
                  <TextField
                    size="small"
                    value={newVariant.ram}
                    onChange={(e) =>
                      setNewVariant({ ...newVariant, ram: e.target.value })
                    }
                    placeholder="RAM"
                  />
                </Box>
              )}
              <Box flexBasis={"25%"}>
                <TextField
                  size="small"
                  value={newVariant.storage}
                  onChange={(e) =>
                    setNewVariant({ ...newVariant, storage: e.target.value })
                  }
                  placeholder="Storage"
                />
              </Box>
              <Box
                flexBasis={"5%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <CustomTypography variant="subtitle2"> - </CustomTypography>
              </Box>
              <Box flexBasis={"25%"}>
                <TextField
                  size="small"
                  type="number"
                  value={newVariant.productPrice}
                  onChange={(e) =>
                    setNewVariant({
                      ...newVariant,
                      productPrice: parseInt(e.target.value),
                    })
                  }
                  placeholder="Price"
                />
              </Box>
              <Box flexBasis={"20%"}>
                <Button
                  onClick={handleAddVariant}
                  sx={{
                    color: "white",
                    backgroundColor: "rgb(6, 155, 170)",
                    borderRadius: "3rem",
                    width: "100%",
                    "&:hover": {
                      backgroundColor: "rgb(5, 135, 150)",
                    },
                  }}
                >
                  Add
                </Button>
              </Box>
            </Box>
          )}

          {variantData?.isConstantRam && (
            <>
              {" "}
              <Box sx={{ marginBottom: "1rem" }}>
                <Box sx={{ padding: "0 1rem 0 1rem" }}>
                  <CustomTypography
                    variant={"subtitle2"}
                    sx={{ fontWeight: "500" }}
                  >
                    Constant RAM
                  </CustomTypography>
                </Box>
                <Box display={"flex"}>
                  <Box
                    flexBasis={"30%"}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"0.5rem"}
                    sx={{ padding: "0 1rem 0 1rem" }}
                  >
                    <Box>
                      <TextField
                        disabled={!editingConstantRam}
                        size="small"
                        value={ram}
                        onChange={(e) => setRam(e.target.value)}
                      />
                    </Box>
                  </Box>
                  <Box
                    flexBasis={"20%"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.25rem",
                    }}
                  >
                    {editingConstantRam ? (
                      <Button
                        onClick={handleConstantRamUpdate}
                        sx={{
                          color: "white",
                          backgroundColor: "rgb(6, 155, 170)",
                          borderRadius: "3rem",
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          padding: "0.5rem 1rem",
                          whiteSpace: "nowrap",
                          "&.Mui-disabled": {
                            backgroundColor: "rgb(254, 123, 121)",
                            color: "white",
                          },
                          "&:hover": {
                            backgroundColor: "white",
                            color: "black",
                            border: "1px solid rgb(6, 155, 170)",
                          },
                        }}
                      >
                        Update
                      </Button>
                    ) : (
                      <IconButton>
                        <Edit onClick={() => setEditingConstantRam(true)} />
                      </IconButton>
                    )}

                    <IconButton>
                      <Cancel
                        sx={{ color: "#fd5c63" }}
                        onClick={() => setEditingConstantRam(false)}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </>
          )}

          <Box
            display={"flex"}
            gap={"0.5rem"}
            sx={{ padding: "0 1rem 0 1rem" }}
          >
            {!variantData?.isConstantRam && (
              <Box
                display={"flex"}
                flexBasis={"25%"}
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                <CustomTypography
                  variant={"subtitle2"}
                  sx={{ fontWeight: "500" }}
                >
                  RAM
                </CustomTypography>
              </Box>
            )}
            <Box
              display={"flex"}
              flexBasis={"25%"}
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontWeight: "500" }}
              >
                Storage
              </CustomTypography>
            </Box>
            <Box
              display={"flex"}
              flexBasis={"5%"}
              sx={{ justifyContent: "center", alignItems: "center" }}
            ></Box>
            <Box
              display={"flex"}
              flexBasis={"25%"}
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontWeight: "500" }}
              >
                Price
              </CustomTypography>
            </Box>
            <Box
              display={"flex"}
              flexBasis={"20%"}
              sx={{ justifyContent: "center", alignItems: "center" }}
            ></Box>
          </Box>

          {editedVariants.map((variant, index) =>
            renderVariantRow(variant, index)
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default VariantEditingModal;
