import React, { useState, forwardRef } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CustomTypography from "../../../../../../muicomponents/shared/CustomTypography";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import OfferBadge from "../shared/OfferBadge";
import ProductTitle from "../shared/ProductTitle";
import ProductCondition from "../shared/ProductCondition";
import ProductPrice from "../shared/ProductPrice";
import EmiPlan from "../shared/EmiPlan";
import Discounts from "../shared/Discounts";

const Product = forwardRef(({ product }, ref) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("desktop"));

  const inStock = product.quantity > 0;

  const handleClick = () => {
    navigate(`/buying-product-details/${product.id}`);
  };

  return (
    <Box
      ref={ref}
      className="product-card"
      display={"flex"}
      flexDirection={"column"}
      position={"relative"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
      sx={{
        padding: "0 0 0.2rem 0",
        width: isSmallScreen ? "calc(50% - 0.5rem)" : "calc(33.33% - 0.7rem)",
        borderRadius: "0.5rem",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        minHeight: "auto",
        transition: "transform 0.3s ease-in-out",
        transformOrigin: "center",

        "&:hover": {
          border: inStock
            ? "2px solid rgb(6, 155, 170)"
            : "2px solid rgb(132, 136, 132)",
          minHeight: "490px",
          cursor: "pointer",
        },
      }}
    >
      <OfferBadge
        price={product.productPrice}
        mrp={product.mrp}
        inStock={inStock}
      />
      <Box sx={{ width: "100%" }}>
        <img
          className={inStock ? "" : "grayscale-img"}
          src={`${`https://hellofi.s3.ap-south-1.amazonaws.com/`}${
            product?.images[0]?.md
          }`}
          style={{
            width: "100%",
            borderTopLeftRadius: "0.5rem",
            borderTopRightRadius: "0.5rem",
            height: "260px",
            objectFit: "cover",
          }}
        />
      </Box>
      {!inStock && (
        <Box>
          <CustomTypography
            variant={"subtitle2"}
            sx={{
              display: "inline-block",
              borderRadius: "0 0.4rem 0.4rem 0",

              background:
                "linear-gradient(rgb(188, 188, 188) 0%, rgb(153, 153, 153) 100%)",
              padding: "0 1rem",
              fontWeight: "300",
              color: "white",
            }}
          >
            Out of Stock
          </CustomTypography>
        </Box>
      )}
      <Box sx={{ padding: "0.5rem 0" }}>
        <ProductTitle title={product.productName} />
        <ProductCondition condition={product.condition} inStock={inStock} />
        {/* {product && product.warranty && (
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={"0.3rem"}
            sx={{ padding: "0 1rem" }}
          >
            <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "500" }}>
              Warranty:
            </CustomTypography>
            <CustomTypography
              variant={"subtitle2"}
              sx={{ fontWeight: "700", color: "rgb(6, 155, 170)" }}
            >
              {product.warranty}
            </CustomTypography>
          </Box>
        )} */}
        <ProductPrice
          price={product.productPrice}
          mrp={product.mrp}
          inStock={inStock}
        />
        <EmiPlan emiBasePrice={product.emiBasePrice} />
        <Discounts />
        {isHovered && (
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            sx={{ padding: "0.5rem 1rem" }}
          >
            <ArrowForward
              sx={{
                fontSize: "3rem",
                color: inStock ? "rgb(6, 155, 170)" : "rgb(132, 136, 132)",
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
});

export default Product;
