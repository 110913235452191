import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import DiscountFunction from "../../../../../utils/DiscountFunction";
import {
  KeyboardArrowRight,
  LocalShippingOutlined,
  Cancel,
  Pending,
} from "@mui/icons-material";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

const OrderCard = ({ product, order, unit, quantity, payment, itemId }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("tablet"));
  const timestamp = new Date(Number(order.createdAt));
  const formattedDate = format(timestamp, "EEE, dd MMM");

  const orderStatus = unit?.status[unit.status.length - 1];
  const firstOrderStatus = unit?.status[0];

  const handleNavigate = () => {
    navigate(`/order_details?order_id=${order.orderNo}&item_id=${itemId}`);
  };
  return (
    <>
      <Box>
        <Box sx={{ padding: "0 1rem" }}>
          <CustomTypography
            variant={"subtitle1"}
            sx={{
              color: "#5E5C5C",
              fontSize: isMobileScreen ? "0.85rem" : "1rem",
            }}
          >
            #Order {order.orderNo}
          </CustomTypography>
        </Box>
        <Box
          onClick={handleNavigate}
          sx={{
            width: "100%",
            borderRadius: "1rem",
            background: `linear-gradient(120deg,#F6EDED 8%,#DFE8EA 76%)`,
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              padding: isMobileScreen ? "0.75rem" : "1.5rem 1.5rem 1rem 1.5rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "2px dashed #C2BEBE",
            }}
          >
            <Box
              sx={{ display: "flex", gap: isMobileScreen ? "0.75rem" : "1rem" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: isMobileScreen ? "40px" : "60px",
                  height: isMobileScreen ? "40px" : "60px",
                  backgroundColor: "white",
                  gap: "1rem",
                  borderRadius: "50%",
                  border: "1px solid #C2BEBE",
                }}
              >
                {(orderStatus && orderStatus?.status === "failed") ||
                orderStatus?.status === "cancelled" ? (
                  <Cancel
                    sx={{
                      color: "#f16565",
                      fontSize: isMobileScreen ? "1.5rem" : "2rem",
                    }}
                  />
                ) : payment?.paymentStatus === "pending" &&
                  unit?.status.length === 0 ? (
                  <>
                    <Pending
                      sx={{
                        color: "#E49B0F",
                        fontSize: isMobileScreen ? "1.5rem" : "2rem",
                      }}
                    />
                  </>
                ) : (
                  <LocalShippingOutlined
                    sx={{
                      color: "rgb(6, 155, 170)",
                      fontSize: isMobileScreen ? "1.5rem" : "2rem",
                    }}
                  />
                )}
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
              >
                {orderStatus && orderStatus?.status == "failed" ? (
                  <>
                    <CustomTypography
                      variant={"subtitle1"}
                      sx={{
                        fontWeight: "600",
                        fontSize: isMobileScreen ? "0.85rem" : "1rem",
                      }}
                    >
                      Payment Status:{" "}
                      <span style={{ color: "#f16565" }}>Failed</span>
                    </CustomTypography>
                    <CustomTypography
                      variant={"subtitle2"}
                      sx={{ fontSize: isSmallScreen && "0.75rem" }}
                    >
                      {formattedDate}
                    </CustomTypography>
                  </>
                ) : payment?.paymentStatus == "pending" &&
                  unit?.status.length === 0 ? (
                  <>
                    {" "}
                    <CustomTypography
                      variant={"subtitle1"}
                      sx={{
                        fontWeight: "600",
                        fontSize: isMobileScreen ? "0.85rem" : "1rem",
                      }}
                    >
                      Payment Status:{" "}
                      <span style={{ color: "#E49B0F" }}>Pending</span>
                    </CustomTypography>
                    <CustomTypography
                      variant={"subtitle2"}
                      sx={{ fontSize: isSmallScreen && "0.75rem" }}
                    >
                      {formattedDate}
                    </CustomTypography>
                  </>
                ) : (
                  <>
                    <CustomTypography
                      variant={"subtitle1"}
                      sx={{
                        fontWeight: "600",
                        fontSize: isMobileScreen ? "0.85rem" : "1rem",
                      }}
                    >
                      Order Status:{" "}
                      <span
                        style={{
                          fontWeight: 700,
                          color:
                            orderStatus?.status === "cancelled"
                              ? "#f16565"
                              : "rgb(6, 155, 170)",
                        }}
                      >
                        Order {orderStatus && orderStatus?.status}
                      </span>
                    </CustomTypography>
                    <CustomTypography
                      variant={"subtitle2"}
                      sx={{ fontSize: isSmallScreen && "0.75rem" }}
                    >
                      {formattedDate}
                    </CustomTypography>
                  </>
                )}
              </Box>
            </Box>
            <Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  width: isMobileScreen ? "30px" : "45px",
                  height: isMobileScreen ? "30px" : "45px",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  border: "1px solid #C2BEBE",
                }}
              >
                <KeyboardArrowRight />
              </Box>
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={isMobileScreen ? "0.75rem" : "1rem"}
            sx={{
              padding: isMobileScreen ? "0.75rem" : "1rem",
              width: "100%",
              height: "100%",
            }}
          >
            <Box>
              <img
                src={`${`https://hellofi.s3.ap-south-1.amazonaws.com/`}${
                  product.images[0].sm
                }`}
                style={{
                  width: isSmallScreen ? "100px" : "125px",
                  height: isSmallScreen ? "100px" : "125px",
                  objectFit: "cover",
                  borderRadius: "1rem",
                }}
              />
            </Box>
            <Box display={"flex"} flexDirection={"column"} flexGrow={"1"}>
              <CustomTypography
                variant={"subtitle1"}
                sx={{
                  fontWeight: "600",
                  display: "-webkit-box",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  lineHeight: "1.5em",
                  maxHeight: "3em",
                  fontSize: isMobileScreen ? "0.85rem" : "1rem",
                }}
              >
                {product.productName}
              </CustomTypography>

              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontSize: isMobileScreen ? "0.80rem" : "0.875rem" }}
              >
                Condition:{" "}
                <span style={{ fontWeight: 700, color: "rgb(6, 155, 170)" }}>
                  {product.condition}
                </span>
              </CustomTypography>
              <CustomTypography variant={"subtitle2"}>
                <span
                  style={{
                    borderRadius: "1rem",
                    backgroundColor: "#D8CACA",
                    padding: "2px 8px",
                    fontWeight: "700",
                    fontSize: isMobileScreen ? "0.8rem" : "0.875rem",
                  }}
                >
                  Qty: {quantity}
                </span>
              </CustomTypography>
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                gap={"0.5rem"}
              >
                <CustomTypography
                  variant={"subtitle1"}
                  sx={{
                    fontWeight: "700",
                    fontSize: isMobileScreen ? "0.85rem" : "1rem",
                  }}
                >
                  ₹{product.productPrice}{" "}
                </CustomTypography>
                <CustomTypography
                  variant={"subtitle1"}
                  style={{
                    color: "#94969f",
                    fontSize: isMobileScreen ? "0.85rem" : "1rem",
                    fontWeight: 0,
                    textDecoration: "line-through",
                  }}
                >
                  {product.mrp}
                </CustomTypography>
                <CustomTypography
                  variant={"subtitle1"}
                  sx={{
                    fontWeight: "500",
                    color: "#f16565",
                    fontSize: isMobileScreen ? "0.85rem" : "1rem",
                  }}
                >
                  {DiscountFunction(product.productPrice, product.mrp)}% OFF
                </CustomTypography>
              </Box>
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontSize: isMobileScreen ? "0.75rem" : "0.875rem" }}
              >
                Warranty:{" "}
                <span style={{ fontWeight: 700 }}>{product.warranty}</span>
              </CustomTypography>
              {!isSmallScreen &&
                payment &&
                payment?.paymentType !== "cod" &&
                (payment?.paymentStatus === "failed" ||
                  payment?.paymentStatus === "pending") &&
                payment.paymentType !== "remaining" && (
                  <Box>
                    <CustomTypography
                      variant={"subtitle2"}
                      sx={{
                        display: "inline-block",
                        borderRadius: "0.5rem",
                        padding: "0.5rem",
                        maxWidth: isSmallScreen ? "100%" : "70%",
                        border: "1px solid #2a55e5",
                        fontSize: isMobileScreen ? "0.5rem" : "0.65rem",
                        backgroundColor: "#f0f5ff",
                      }}
                    >
                      {payment.message}
                    </CustomTypography>
                  </Box>
                )}
            </Box>
          </Box>
          {isSmallScreen &&
            payment &&
            (firstOrderStatus?.status === "failed" ||
              firstOrderStatus?.status === undefined) &&
            payment?.paymentType !== "cod" &&
            (payment?.paymentStatus === "failed" ||
              payment?.paymentStatus === "pending") &&
            payment.paymentType !== "remaining" && (
              <Box sx={{ padding: "0 0.75rem 0.75rem 0.75rem" }}>
                <CustomTypography
                  variant={"subtitle2"}
                  sx={{
                    display: "inline-block",
                    borderRadius: "0.5rem",
                    padding: isSmallScreen ? "0.5rem" : "1rem",
                    maxWidth: isSmallScreen ? "100%" : "70%",
                    border: "1px solid #2a55e5",
                    fontSize: isMobileScreen ? "0.75rem" : "0.875rem",
                    backgroundColor: "#f0f5ff",
                  }}
                >
                  {payment.message}
                </CustomTypography>
              </Box>
            )}
        </Box>
      </Box>
    </>
  );
};

export default OrderCard;
