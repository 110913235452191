import React, { useEffect } from "react";
import Footer from "./Footer";
import Navigation from "./Navigation";
import "../ComponentsCss/AllNews.css"; // Ensure this CSS file includes zigzag styling
import { useQuery } from "@apollo/client";
import { GET_ALL_NEWS } from "../graphql/Query";

export default function AllNews() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    data: getAllNews,
    loading: newsLoading,
    error,
  } = useQuery(GET_ALL_NEWS);

  if (newsLoading) return <h1>Loading...</h1>;
  if (error) return <p>Error :(</p>;

  return (
    <>
      <Navigation />

      <div className="news-container">
        <div className="news-items-container">
          {getAllNews.getAllNews.map((newsItem, index) => (
            <div
              key={newsItem.id}
              className={`news-item ${index % 2 === 0 ? "left" : "right"}`}
            >
              <img
                src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${newsItem.imageKey}`}
                alt="NEWS"
                className="news-image"
              />
              <div className="news-item-content">
                <div class="three">
                  <h3 className="news-title">{newsItem.title}</h3>
                  <div className="linenews mt-3 position-relative my-3">
                    <div className="line_inner_news"></div>
                  </div>
                </div>
                <p>{newsItem.description}</p>
                <a href={newsItem.newsLink} target="_blank" rel="noreferrer">
                  Read More
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
}
