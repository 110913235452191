import { Box } from "@mui/material";
import React from "react";
import Navbar from "../navigation/Navbar";
import NewFooter from "../NewFooter";
import { Outlet } from "react-router-dom";
import Header from "../navigation/Header";

const LaptopLayout = () => {
  return (
    <>
      <Header />
      <Navbar />
      <Outlet />
      <NewFooter />
    </>
  );
};

export default LaptopLayout;
