import {
  Box,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableContainer,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button,
  Input,
} from "@mui/material";
import React, { useState } from "react";
import CustomTypography from "../../muicomponents/shared/CustomTypography";
import { useMutation, useQuery } from "@apollo/client";
import { MUTATION_ADD_CATEGORY } from "../../graphql/Mutations";
import swal from "sweetalert";
import { QUERY_GET_ALL_CATEGORY } from "../../graphql/Query";
import { FaEye } from "react-icons/fa";

const AddCategory = () => {
  const getImageName = (invoiceLink) => {
    if (!invoiceLink) return "";
    const parts = invoiceLink.split("/");
    return parts[parts.length - 1];
  };
  const {
    data,
    loading: categoryData,
    error,
  } = useQuery(QUERY_GET_ALL_CATEGORY);

  const [addCategory, { loading }] = useMutation(MUTATION_ADD_CATEGORY, {
    refetchQueries: [QUERY_GET_ALL_CATEGORY],
  });
  const [category, setCategory] = useState({
    name: "",
    seoName: "",
    categoryType: "sell",
    file: null,
  });

  const handleAddCategory = async () => {
    try {
      const formData = new FormData();
      formData.append("file", category.file);
      const { data } = await addCategory({
        variables: {
          name: category.name,
          seoName: category.seoName,
          categoryType: category.categoryType,
          file: formData.get("file"),
        },
      });
      swal({
        title: "Success",
        text: data.addCategory.message,
        icon: "success",
      });
      setCategory({ name: "", seoName: "", categoryType: "sell", file: null });
    } catch (error) {}
  };

  const handleChange = (event) => {
    setCategory({ ...category, categoryType: event.target.value });
  };

  const handleInputChange = (event) => {
    setCategory({ ...category, [event.target.name]: event.target.value });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCategory({ ...category, file: file });
    }
  };
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"1rem"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ margin: "3rem 0" }}
    >
      <Box
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          padding: "1rem",
          borderRadius: "0.5rem",
          gap: "1rem",
        }}
      >
        <Box>
          <CustomTypography variant={"h6"} sx={{ fontWeight: "600" }}>
            Add Category
          </CustomTypography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            alignItems: "center",
            width: { laptop: "400px", xs: "100%" },
            minHeight: "200px",
            backgroundColor: "white",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              label="Category Name"
              variant="outlined"
              name="name"
              value={category.name}
              onChange={handleInputChange}
              size="small"
              sx={{ width: "100%" }}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              label="Category Seo Name"
              variant="outlined"
              name={"seoName"}
              value={category.seoName}
              onChange={handleInputChange}
              size="small"
              sx={{ width: "100%" }}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                select category type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category.categoryType}
                label="select category state"
                size="small"
                onChange={handleChange}
                sx={{ width: "100%" }}
              >
                <MenuItem value={"sell"}>sell</MenuItem>{" "}
                <MenuItem value={"buy"}>buy</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Box>
              <Input
                accept="image/*"
                id="category-image-upload"
                type="file"
                onChange={handleImageChange}
                sx={{ width: "100%" }}
              />
            </Box>
          </Box>
          <Box>
            <Button
              type="submit"
              variant="outlined"
              onClick={handleAddCategory}
            >
              Add Category
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: { xs: "100%", laptop: "800px" } }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Sl No.</TableCell>
                <TableCell>Category Name</TableCell>
                <TableCell>Category Seo Name</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Category Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.getAllCategory.map((category, index) => (
                <TableRow>
                  <TableCell align="right">{++index}</TableCell>
                  <TableCell align="right">{category?.name}</TableCell>
                  <TableCell align="right">{category?.seoName}</TableCell>
                  <TableCell align="right">{category?.categoryType}</TableCell>
                  <TableCell align="center">
                    <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
                      <Box> {getImageName(category?.image)}</Box>
                      <Box>
                        {" "}
                        <FaEye
                          style={{ cursor: "pointer", fontSize: "1rem" }}
                          onClick={() =>
                            window.open(
                              `${`https://hellofi.s3.ap-south-1.amazonaws.com/`}${
                                category?.image
                              }`,
                              "_blank"
                            )
                          }
                        />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default AddCategory;
