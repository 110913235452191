import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Spinner,
  Image,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";

import "../../ComponentsCss/AdminCss/AddProduct.css";
import {
  DELETE_SELLING_BRAND_BANNER_IMAGE,
  MUTATION_CREATE_BRAND,
  UPDATE_SELLING_BRAND_IMAGE,
} from "../../graphql/Mutations";
import { MUTATION_DELETE_BRAND } from "../../graphql/Mutations";
import { MUTATION_UPDATE_BRAND } from "../../graphql/Mutations";
import { MUTATION_UPDATE_BRAND_IMAGE } from "../../graphql/Mutations";
import { QUERY_GET_ALL_BRANDS } from "../../graphql/Query";
import { FaEye, FaPencilAlt } from "react-icons/fa";

import AWS from "aws-sdk";
import uniqid from "uniqid";
import swal from "sweetalert";
import { Box, CircularProgress } from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import { useSetRecoilState } from "recoil";
import { cartPageBackdrop } from "../../store/atoms/addToCart/addToCart";
import MuiBackdrop from "../../muicomponents/shared/MuiBackdrop";

const ID = "AKIA6GB4RFKTJXDPLPOH";
const SECRET = "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";
const BUCKET_NAME = "sanjaytestbucket";

const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});

export default function AddSellBrand() {
  let count = 1;
  const [validated, setValidated] = useState(false);

  const { data: getAllBrands, loading: allBrandLoading } =
    useQuery(QUERY_GET_ALL_BRANDS);

  const [uploadBrandBannerImage, { loading: uploadingImageLoading }] =
    useMutation(UPDATE_SELLING_BRAND_IMAGE, {
      refetchQueries: [QUERY_GET_ALL_BRANDS],
    });

  const [deleteBrandBannerImage, { loading: brandBannerLoading }] = useMutation(
    DELETE_SELLING_BRAND_BANNER_IMAGE,
    {
      refetchQueries: [QUERY_GET_ALL_BRANDS],
    }
  );
  const [brandName, setBrandName] = useState("");
  const [image, setImage] = useState("");
  const [brandCategory, setBrandCategory] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [brandNameEdit, setBrandNameEdit] = useState("");
  const [brandCategoryEdit, setBrandCategoryEdit] = useState("");
  const [imageEdit, setImageEdit] = useState("");
  const [brandFileData, setBrandFileData] = useState({});

  const [smShow, setSmShow] = useState(false);
  const [brandId, setBrandId] = useState("");
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);
  const [deleteBrandById, { loading: dltLoading }] = useMutation(
    MUTATION_DELETE_BRAND,
    {
      refetchQueries: [QUERY_GET_ALL_BRANDS],
    }
  );
  const [createMobileBrand, { loading: sellLoading }] = useMutation(
    MUTATION_CREATE_BRAND,
    {
      refetchQueries: [QUERY_GET_ALL_BRANDS],
    }
  );
  const [updateBrandById] = useMutation(MUTATION_UPDATE_BRAND, {
    refetchQueries: [QUERY_GET_ALL_BRANDS],
  });
  const [updateBrandImage] = useMutation(MUTATION_UPDATE_BRAND_IMAGE, {
    refetchQueries: [QUERY_GET_ALL_BRANDS],
  });

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      try {
        const uniqueId = uniqid();
        var regex = new RegExp("[^.]+$");
        var extension = image.name.match(regex);
        var uniqueFileName = "hellofi-img" + uniqueId + "." + extension[0];
        const params = {
          Bucket: BUCKET_NAME,
          Key: uniqueFileName,
          Body: image,
        };
        s3.upload(params, function (err, data) {
          if (err) {
            throw err;
          } else {
            createMobileBrand({
              variables: {
                mobileBrandInput: {
                  brandName: `${brandName}`,
                  category: `${brandCategory}`,
                  brandImage: `${uniqueFileName}`,
                },
              },
            });
            swal({
              title: "Success",
              text: "Brand Add successfully",
              icon: "success",
            });

            setImage("");
            setBrandCategory("");
            setBrandName("");
          }
        });
      } catch (err) {}
    }
  };

  const getImageName = (invoiceLink) => {
    if (!invoiceLink) return "";
    const parts = invoiceLink.split("/");
    console.log(parts[parts.length - 1]);
    return parts[parts.length - 1];
  };

  const handleFileChange = (event, brandId) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;

    setBrandFileData((prevData) => ({
      ...prevData,
      [brandId]: {
        file: selectedFile,
        fileName: selectedFile.name,
        uploadStatus: "idle",
        errorMessage: "",
      },
    }));
  };

  const handleImageDelete = async (brandId) => {
    try {
      setBackdropOpen(true);
      const { data } = await deleteBrandBannerImage({
        variables: {
          brandId: brandId,
        },
      });
      swal({
        title: "Success",
        text: data.deleteBrandBannerImage.message,
        icon: "success",
      });
    } catch (error) {
      console.error("Error uploading image:", error);
      swal({
        title: "Error!!!",
        text: "Error Deleting Image",
        icon: "error",
      });
    } finally {
      setBackdropOpen(false);
    }
  };

  const handleImageUpload = async (brandId) => {
    const brandData = brandFileData[brandId];
    if (!brandData || !brandData.file) return;

    const formData = new FormData();
    formData.append("file", brandData.file);

    try {
      setBackdropOpen(true);
      setBrandFileData((prevData) => ({
        ...prevData,
        [brandId]: { ...brandData, uploadStatus: "uploading" },
      }));
      const { data } = await uploadBrandBannerImage({
        variables: {
          brandId: brandId,
          file: formData.get("file"),
        },
      });
      swal({
        title: "Success",
        text: data.uploadBrandBannerImage.message,
        icon: "success",
      });
      setBrandFileData((prevData) => ({
        ...prevData,
        [brandId]: {
          ...brandData,
          file: null,
          fileName: "",
          uploadStatus: "success",
          errorMessage: "",
        },
      }));
    } catch (error) {
      console.error("Error uploading image:", error);
      swal({
        title: "Error!!!",
        text: "Error uploading Image",
        icon: "error",
      });
      setBrandFileData((prevData) => ({
        ...prevData,
        [brandId]: {
          ...brandData,
          uploadStatus: "error",
          errorMessage: "Error uploading image",
        },
      }));
    } finally {
      setBackdropOpen(false);
    }
  };

  const showDelModal = (id) => {
    setSmShow(true);
    setBrandId(id);
  };
  const handleDeleteBrand = () => {
    deleteBrandById({
      variables: {
        brandId: `${brandId}`,
      },
    });
    swal({
      title: "Deleted",
      text: "Brand Delete successfully",
      icon: "success",
    });
    setSmShow(false);
  };
  const [editModal, setEditModal] = useState(false);
  const handleclose = () => {
    setEditModal(false);
    setBrandCategoryEdit("");
  };
  const showEditModal = (selectedbrand) => {
    setEditModal(true);
    setSelectedBrand(selectedbrand);
    setBrandNameEdit(selectedbrand.brandName);
    setBrandCategoryEdit(selectedbrand.category);
  };
  const handleEdit = () => {
    updateBrandById({
      variables: {
        brandId: `${selectedBrand.id}`,
        brandName: `${brandNameEdit}`,
        brandCategory: `${brandCategoryEdit}`,
      },
    }).then((data) => {
      setEditModal(false);
    });
  };
  const [imageModal, setImageModal] = useState(false);
  const handleCloseImageModal = () => {
    setImageModal(false);
  };
  const handleChangeImage = () => {
    try {
      const uniqueId = uniqid();
      var regex = new RegExp("[^.]+$");
      var extension = imageEdit.name.match(regex);
      var uniqueFileName = "hellofi-img" + uniqueId + "." + extension[0];
      const params = {
        Bucket: BUCKET_NAME,
        Key: uniqueFileName,
        Body: imageEdit,
      };
      s3.upload(params, function (err, data) {
        if (err) {
          throw err;
        } else {
          swal({
            title: "Successfull!!!",
            text: " Uploaded Successfully",
            icon: "success",
          });
        }
      });
    } catch (err) {
      swal({
        title: "Error!!!",
        text: "Error please try again",
        icon: "error",
      });
    }
    updateBrandImage({
      variables: {
        brandId: `${brandId}`,
        brandImage: `${uniqueFileName}`,
      },
    })
      .then(() => {
        setImageModal(false);
      })
      .catch((e) => console.log(e));
  };
  const handleImageEdit = (id) => {
    setBrandId(id);
    setImageModal(true);
  };
  return (
    <>
      <MuiBackdrop />
      <Container style={{ padding: 20 }} fluid>
        <Row>
          <Col md={4}>
            <div style={{ padding: "1rem" }} className="box-shadow">
              <h3 style={{ textAlign: "center" }}>Add Brand Here</h3>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Control
                      required
                      type="text"
                      className="txtinpt"
                      placeholder="Brand Name "
                      onChange={(e) => setBrandName(e.target.value)}
                      value={brandName}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationCustom02">
                    <Dropdown style={{ marginTop: "1rem" }}>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {" "}
                        Category
                      </Dropdown.Toggle>
                      <span>{brandCategory}</span>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={(e) => setBrandCategory("Phones")}
                        >
                          Phones
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setBrandCategory("Laptops")}
                        >
                          Laptops
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setBrandCategory("Tablets")}
                        >
                          Tablets
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setBrandCategory("Smart Watches")}
                        >
                          Smart Watches
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setBrandCategory("Desktop/Imac")}
                        >
                          Desktop/Imac
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setBrandCategory("Other Accessories")}
                        >
                          Other Accessories
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationCustom02">
                    <Form.Control
                      required
                      type="file"
                      className="txtinpt"
                      style={{ marginTop: 20 }}
                      placeholder="Image"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                  </Form.Group>
                </Row>
                <Button type="submit" className="mx-auto d-block">
                  Add Brand
                </Button>
              </Form>
            </div>
          </Col>

          <Col>
            <Table responsive striped bordered hover>
              <thead className="table-head">
                <tr>
                  <th>s.no.</th>
                  <th>Brand Name</th>
                  <th>Brand Category</th>
                  <th>Brand Image</th>
                  <th>Add Brand Banner</th>
                  <th>Remove Brand</th>
                  <th>Edit Brand</th>
                </tr>
              </thead>
              <tbody>
                {allBrandLoading ? (
                  <Spinner
                    animation="border"
                    variant="success"
                    className="mx-auto d-block"
                  />
                ) : (
                  getAllBrands &&
                  getAllBrands.getAllBrands
                    .slice(0)
                    .reverse()
                    .map((data) => (
                      <tr className="table-data">
                        <td>{count++}</td>
                        <td>{data.brandName}</td>
                        <td>{data.category}</td>
                        <td>
                          <Image
                            fluid
                            src={`https://hellofi.s3.ap-south-1.amazonaws.com/${data.brandImage}`}
                            style={{ height: "80%", width: 80 }}
                          ></Image>
                          <FaPencilAlt
                            className="edit-icon"
                            onClick={() => {
                              handleImageEdit(data.id);
                            }}
                          />
                        </td>
                        <td>
                          {data.brandBanner !== "undefined" &&
                          data.brandBanner !== null &&
                          data.brandBanner !== undefined ? (
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap="0.5rem"
                            >
                              {getImageName(data.brandBanner)}
                              <FaEye
                                style={{ cursor: "pointer", fontSize: "1rem" }}
                                onClick={() =>
                                  window.open(
                                    `${`https://hellofi.s3.ap-south-1.amazonaws.com/`}${
                                      data.brandBanner
                                    }`,
                                    "_blank"
                                  )
                                }
                              />
                              <button
                                style={{
                                  color: "#dc3545",
                                  borderColor: "#dc3545",
                                }}
                                onClick={() => handleImageDelete(data.id)}
                              >
                                delete
                              </button>
                            </Box>
                          ) : (
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="flex-start"
                              gap="0.5rem"
                            >
                              <input
                                type="file"
                                id={`file-input-${data.id}`}
                                onChange={(event) =>
                                  handleFileChange(event, data.id)
                                }
                                style={{ display: "none" }}
                                accept=".png, .jpg, .jpeg, .gif, .bmp, .webp"
                              />
                              <Button
                                onClick={() =>
                                  document
                                    .getElementById(`file-input-${data.id}`)
                                    .click()
                                }
                                startIcon={<FileUpload />}
                              >
                                <span style={{ fontSize: "0.75rem" }}>
                                  {uploadingImageLoading
                                    ? "Uploading..."
                                    : "Add Image"}
                                </span>
                              </Button>

                              {brandFileData[data.id]?.fileName && (
                                <Box>
                                  {brandFileData[data.id].fileName.length > 20
                                    ? brandFileData[data.id].fileName.substring(
                                        0,
                                        20
                                      ) + "..."
                                    : brandFileData[data.id].fileName}
                                </Box>
                              )}

                              {brandFileData[data.id]?.uploadStatus ===
                                "error" && (
                                <Box>{brandFileData[data.id].errorMessage}</Box>
                              )}

                              {brandFileData[data.id]?.file && (
                                <button
                                  onClick={() => handleImageUpload(data.id)}
                                  disabled={
                                    brandFileData[data.id].uploadStatus ===
                                    "uploading"
                                  }
                                >
                                  {brandFileData[data.id].uploadStatus ===
                                  "uploading" ? (
                                    <CircularProgress
                                      size={16}
                                      color="inherit"
                                    />
                                  ) : (
                                    "Submit"
                                  )}
                                </button>
                              )}
                            </Box>
                          )}
                        </td>
                        <td>
                          <Button
                            variant="danger"
                            className="mx-auto d-block"
                            onClick={() => showDelModal(data.id)}
                          >
                            -
                          </Button>
                        </td>
                        <td>
                          <Button
                            variant="success"
                            className="mx-auto d-block"
                            onClick={() => showEditModal(data)}
                          >
                            Edit
                          </Button>
                        </td>
                      </tr>
                    ))
                )}
              </tbody>
            </Table>
          </Col>
        </Row>

        <Modal
          size="sm"
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Body style={{ textAlign: "center" }}>
            <h5>Are you sure you want to delete this Brand ?</h5>
            {dltLoading ? (
              <Spinner animation="border" variant="danger" />
            ) : (
              <Button
                onClick={() => handleDeleteBrand()}
                style={{
                  backgroundColor: "#40a737",
                  border: "none",
                  width: "100%",
                }}
              >
                Yes
              </Button>
            )}
          </Modal.Body>
        </Modal>

        <Modal show={editModal} onHide={handleclose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Brand</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Col} md="12" controlId="validationCustom01">
              <Form.Control
                required
                type="text"
                value={brandNameEdit}
                className="txtinpt"
                onChange={(e) => setBrandNameEdit(e.target.value)}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationCustom02">
              <Dropdown style={{ marginTop: "1rem" }}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Category
                </Dropdown.Toggle>
                <span> {brandCategoryEdit} </span>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={(e) => setBrandCategoryEdit("Phones")}
                  >
                    Phones{" "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => setBrandCategoryEdit("Laptops")}
                  >
                    Laptops{" "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => setBrandCategoryEdit("Tablets")}
                  >
                    Tablets{" "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => setBrandCategoryEdit("Smart Watches")}
                  >
                    {" "}
                    Smart Watches{" "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => setBrandCategoryEdit("Desktop/Imac")}
                  >
                    {" "}
                    Desktop/Imac{" "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => setBrandCategoryEdit("Other Accessories")}
                  >
                    {" "}
                    Other Accessories{" "}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleclose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleEdit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={imageModal} onHide={handleCloseImageModal}>
          <Modal.Header closeButton>
            <Modal.Title> Choose Image </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Col} md="12" controlId="validationCustom02">
              <Form.Control
                required
                type="file"
                className="txtinpt"
                style={{ marginTop: 20 }}
                placeholder="Image"
                onChange={(e) => setImageEdit(e.target.files[0])}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => handleChangeImage()}>
              Change Image
            </Button>
            <Button variant="secondary" onClick={handleCloseImageModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}
