import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import DiscountFunction from "../../../../utils/DiscountFunction";
import OrderTimeline from "./OrderTimeLine";
import { useNavigate } from "react-router-dom";
import OrderTimelineMobile from "./OrderTimelineMobile";

const CurrentItem = ({
  product,
  orderType,
  orderTimelineName,
  payment,
  status,
}) => {
  const navigate = useNavigate();
  const currentStatus = product?.unit?.status?.at(-1);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));

  const getProductDetails = () => (
    <Box flexBasis={isSmallScreen ? "" : "35%"} flexGrow={1}>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <CustomTypography
          variant="subtitle1"
          sx={{
            fontSize: isSmallScreen ? "0.875rem" : "1rem",
            fontWeight: "600",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            overflow: "hidden",
            lineHeight: "1.5em",
            maxHeight: "3em",
          }}
        >
          {product.product.productName}
        </CustomTypography>
        <CustomTypography
          variant="subtitle2"
          sx={{ fontSize: isSmallScreen ? "0.80rem" : "0.85rem" }}
        >
          Condition:{" "}
          <span style={{ fontWeight: 700, color: "rgb(6, 155, 170)" }}>
            {product.product.condition}
          </span>
        </CustomTypography>
        <CustomTypography variant="subtitle2">
          <span
            style={{
              borderRadius: "1rem",
              backgroundColor: "#D8CACA",
              padding: "2px 8px",
              fontWeight: "700",
              fontSize: isSmallScreen ? "0.80rem" : "0.85rem",
            }}
          >
            Qty: {product.quantity}
          </span>
        </CustomTypography>
        <Box display="flex" gap="0.5rem" alignItems="center">
          <CustomTypography
            variant="subtitle1"
            sx={{
              fontWeight: "700",
              fontSize: isSmallScreen ? "0.875rem" : "1rem",
            }}
          >
            ₹{product.product.productPrice}
          </CustomTypography>
          <CustomTypography
            variant="subtitle1"
            sx={{
              textDecoration: "line-through",
              color: "#94969f",
              fontSize: isSmallScreen ? "0.875rem" : "1rem",
            }}
          >
            {product.product.mrp}
          </CustomTypography>
          <CustomTypography
            variant="subtitle1"
            sx={{
              fontWeight: "500",
              color: "#f16565",
              fontSize: isSmallScreen ? "0.875rem" : "1rem",
            }}
          >
            {DiscountFunction(
              product.product.productPrice,
              product.product.mrp
            )}
            % OFF
          </CustomTypography>
        </Box>
        <CustomTypography
          variant="subtitle2"
          sx={{ fontSize: isSmallScreen ? "0.80rem" : "0.85rem" }}
        >
          Warranty:{" "}
          <span style={{ fontWeight: 700 }}>{product.product.warranty}</span>
        </CustomTypography>
      </Box>
    </Box>
  );

  const renderFailedStatus = () => (
    <Box>
      <CustomTypography
        variant="subtitle1"
        sx={{
          fontWeight: "600",
          fontSize: isSmallScreen ? "0.875rem" : "1rem",
        }}
      >
        {currentStatus?.message}
      </CustomTypography>
      <CustomTypography
        variant="body2"
        sx={{
          fontWeight: "500",
          color: "#717478",
          fontSize: isSmallScreen ? "0.80rem" : "0.85rem",
        }}
      >
        {currentStatus?.description}. <br />
        {payment.message}
      </CustomTypography>
      <Button
        variant="contained"
        size="small"
        onClick={() => navigate("/checkout/cart")}
        sx={{
          borderRadius: "0.2rem",
          padding: "0.5rem 1rem",
          backgroundColor: "rgb(6, 155, 170)",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: "rgb(6, 155, 170)",
          },
        }}
      >
        <CustomTypography
          variant="subtitle2"
          sx={{
            fontWeight: "500",
            fontSize: isSmallScreen ? "0.80rem" : "0.85rem",
          }}
        >
          Place Order Again
        </CustomTypography>
      </Button>
    </Box>
  );

  const renderOrderTimeline = () => (
    <OrderTimeline
      currentStatus={currentStatus?.status}
      orderType={orderType}
      orderTimelineName={orderTimelineName}
    />
  );

  return (
    product && (
      <>
        {isSmallScreen &&
          currentStatus?.status !== "failed" &&
          currentStatus?.status !== undefined && (
            <Box
              sx={{
                background: "linear-gradient(120deg,#F6EDED 8%,#DFE8EA 76%)",
              }}
            >
              <OrderTimelineMobile
                currentStatus={currentStatus?.status}
                orderType={orderType}
                orderTimelineName={orderTimelineName}
              />
            </Box>
          )}
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            background: "linear-gradient(120deg,#F6EDED 8%,#DFE8EA 76%)",
            minHeight: "60px",
            borderRadius: isSmallScreen ? "0" : "0.5rem",
            padding: isSmallScreen ? "0.75rem" : "1rem",
            gap: "0.5rem",
            justifyContent: "center",
          }}
        >
          <Box
            display={"flex"}
            gap={"0.5rem"}
            flexBasis={isSmallScreen ? "100%" : "45%"}
          >
            <Box flexBasis={isSmallScreen ? "10%" : "10%"}>
              <img
                src={`${`https://hellofi.s3.ap-south-1.amazonaws.com/`}${
                  product.product.images[0].sm
                }`}
                style={{
                  width: isSmallScreen ? "100px" : "125px",
                  height: isSmallScreen ? "100px" : "125px",
                  borderRadius: "0.5rem",
                }}
                alt="Product"
              />
            </Box>
            {getProductDetails()}
          </Box>
          <Box
            flexBasis={isSmallScreen ? "100%" : "55%"}
            display="flex"
            alignItems={"center"}
            justifyContent={isSmallScreen ? "flex-start" : "center"}
            sx={{ padding: isSmallScreen ? "0" : "0 4rem" }}
          >
            {currentStatus?.status === "failed" ? (
              renderFailedStatus()
            ) : currentStatus === undefined ? (
              <Box>
                <CustomTypography
                  variant="subtitle1"
                  sx={{ fontWeight: "600" }}
                >
                  Order Pending
                </CustomTypography>
                <CustomTypography
                  variant="subtitle2"
                  sx={{ fontWeight: "600" }}
                >
                  {payment.message}
                </CustomTypography>
              </Box>
            ) : isSmallScreen ? (
              ""
            ) : (
              renderOrderTimeline()
            )}
          </Box>
        </Box>
      </>
    )
  );
};

export default CurrentItem;
