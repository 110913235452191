import { Box } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const SellingTextarea = ({ label, placeHolder }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"0.5rem"}>
      <Box>
        <CustomTypography
          variant={"subtitle1"}
          sx={{ color: "#214C7B", fontWeight: "600" }}
        >
          {label}
        </CustomTypography>
      </Box>
      <Box>
        <textarea
          placeholder=""
          rows={6}
          style={{
            borderRadius: "0.5rem",
            border: "0.5px solid black",
            padding: "0.5rem",
            width: "100%",
          }}
        ></textarea>
      </Box>
    </Box>
  );
};

export default SellingTextarea;
