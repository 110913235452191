import React, { useState } from "react";
import {
  TextField,
  InputAdornment,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import SmartphoneIcon from "@mui/icons-material/Smartphone";

const SellingSearchBar = () => {
  const [query, setQuery] = useState("");
  const [showResults, setShowResults] = useState(false);

  const sampleResults = [
    "Apple iPhone 6",
    "Apple iPhone 7",
    "Apple iPhone 6S",
    "Apple iPhone X",
    "Apple iPhone 8",
  ];

  const handleInputChange = (event) => {
    const value = event.target.value;
    setQuery(value);
    setShowResults(value.length > 0); // Show results if there's input
  };

  const handleClear = () => {
    setQuery("");
    setShowResults(false);
  };

  return (
    <Box width={"100%"} position={"relative"}>
      <TextField
        variant="outlined"
        placeholder="Search Device Models to sell"
        fullWidth
        value={query}
        onChange={handleInputChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: "#8c8c8c" }} />
            </InputAdornment>
          ),
          endAdornment: query && (
            <InputAdornment position="end">
              <IconButton onClick={handleClear}>
                <CloseIcon style={{ color: "#8c8c8c" }} />
              </IconButton>
            </InputAdornment>
          ),
          style: {
            borderRadius: "0.5rem",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            backgroundColor: "#fff",
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
          },
          "& .MuiInputBase-input": {
            padding: "10px 14px",
          },
        }}
      />

      {showResults && (
        <Box
          position={"absolute"}
          width={"100%"}
          bgcolor={"#fff"}
          boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
          borderRadius={"0.5rem"}
          maxHeight={"400px"}
          overflow={"auto"}
          zIndex={12}
        >
          <List>
            {sampleResults.map((item, index) => (
              <ListItem button key={index}>
                <ListItemIcon>
                  <SmartphoneIcon style={{ color: "#8c8c8c" }} />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default SellingSearchBar;
