import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { forwardRef } from "react";
import OfferBadge from "../shared/OfferBadge";
import ProductTitle from "../shared/ProductTitle";
import ProductCondition from "../shared/ProductCondition";
import ProductPrice from "../shared/ProductPrice";
import EmiPlan from "../shared/EmiPlan";
import Discounts from "../shared/Discounts";
import { useNavigate } from "react-router-dom";
import CustomTypography from "../../../../../../muicomponents/shared/CustomTypography";

const ProductSmall = forwardRef(({ product }, ref) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("tablet"));
  const inStock = product.quantity > 0;

  const handleClick = () => {
    navigate(`/buying-product-details/${product.id}`);
  };
  // console.log(product);

  return (
    <Box sx={{ margin: "0.25rem" }} onClick={handleClick} ref={ref}>
      <OfferBadge
        price={product.productPrice}
        mrp={product.mrp}
        inStock={inStock}
      />
      <Box
        display={"flex"}
        flexDirection={"row"}
        gap={"0.5rem"}
        position={"relative"}
        sx={{
          width: "100%",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          minHeight: "150px",
          borderRadius: "0.5rem",
          padding: "1rem 0.8rem",
        }}
      >
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <img
            className={inStock ? "" : "grayscale-img"}
            src={`${`https://hellofi.s3.ap-south-1.amazonaws.com/`}${
              product?.images[0]?.sm
            }`}
            alt=""
            style={{
              width: isSmallScreen ? "112px" : "176px",
              height: isSmallScreen ? "112px" : "176px",
              objectFit: "cover",
              borderRadius: "0.5rem",
            }}
          />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          gap={"0.1rem"}
        >
          <ProductTitle title={product.productName} isSmall={true} />
          <ProductCondition
            condition={product.condition}
            isSmall={true}
            inStock={inStock}
          />
          {!inStock && (
            <Box>
              <CustomTypography
                variant={"subtitle2"}
                sx={{
                  display: "inline-block",
                  borderRadius: "0 0.4rem 0.4rem 0",

                  background:
                    "linear-gradient(rgb(188, 188, 188) 0%, rgb(153, 153, 153) 100%)",
                  padding: "0 1rem",
                  fontWeight: "300",
                  color: "white",
                }}
              >
                Out of Stock
              </CustomTypography>
            </Box>
          )}
          <EmiPlan isSmall={true} emiBasePrice={product.emiBasePrice} />

          <ProductPrice
            price={product.productPrice}
            mrp={product.mrp}
            inStock={inStock}
          />
          <Discounts isSmall={true} />
        </Box>
      </Box>
    </Box>
  );
});

export default ProductSmall;
