import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import Banner1Mobile from "../../../../../Images/home/banners/bannerMobiles/Banner1_624x312.png";
import Banner2Mobile from "../../../../../Images/home/banners/bannerMobiles/Banner2_624x312.jpg";
import Banner3Mobile from "../../../../../Images/home/banners/bannerMobiles/Banner3_624x312.jpg";
import Banner4Mobile from "../../../../../Images/home/banners/bannerMobiles/Banner4_624x312.jpg";
import Banner5Mobile from "../../../../../Images/home/banners/bannerMobiles/Banner5_624x312.jpg";

import Banner1Laptop from "../../../../../Images/home/banners/bannerLaptops/Banner1_2560x732.png";
import Banner2Laptop from "../../../../../Images/home/banners/bannerLaptops/Banner2_2560x732.jpg";
import Banner3Laptop from "../../../../../Images/home/banners/bannerLaptops/Banner3_2560x732.jpg";
import Banner4Laptop from "../../../../../Images/home/banners/bannerLaptops/Banner4_2560x732.jpg";
import Banner5Laptop from "../../../../../Images/home/banners/bannerLaptops/Banner5_2560x732.jpg";
import Banner1 from "../../../../../Images/home/banners/Banner1.png";
import Banner2 from "../../../../../Images/home/banners/Banner2.jpg";
import Banner3 from "../../../../../Images/home/banners/Banner3.jpg";
import Banner4 from "../../../../../Images/home/banners/Banner4.jpg";
import Banner5 from "../../../../../Images/home/banners/Banner5.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper/modules";

const Banner = () => {
  const theme = useTheme();
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("desktop"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("desktop"));
  const images = [
    { src: Banner1Laptop, index: 1 },
    { src: Banner2Laptop, index: 2 },
    { src: Banner3Laptop, index: 3 },
    { src: Banner4Laptop, index: 4 },
    { src: Banner5Laptop, index: 5 },
  ];

  return (
    <Box
      sx={{
        margin: { xs: "0 0.5rem", laptop: 0 },
        position: "relative",
        padding: "0.2rem 0",
        "& .swiper-button-next, & .swiper-button-prev": {
          backgroundColor: "rgba(255, 255, 255, 0.75)",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          transition: "all 0.3s ease",
          "&::after": {
            fontSize: "20px",
            color: "#000",
            fontWeight: "bold",
          },
        },
        "& .swiper-button-next": {
          right: "10px",
        },
        "& .swiper-button-prev": {
          left: "10px",
        },
        "& .swiper-pagination-bullet": {
          width: "8px",
          height: "8px",
          backgroundColor: "#5072A7",
          opacity: 0.5,
        },
        "& .swiper-pagination-bullet-active": {
          opacity: 1,
          backgroundColor: "rgb(6, 155, 170)",
        },
      }}
    >
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={10}
        slidesPerView={1}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        navigation={!isTabletScreen}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        style={{
          boxShadow: "none",
          "--swiper-navigation-size": "16px",
        }}
      >
        {images.map((image) => (
          <SwiperSlide
            key={image.index}
            style={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                position: "relative",
              }}
            >
              <img
                src={image.src}
                alt={`Banner ${image.index}`}
                style={{
                  aspectRatio: isMobileScreen ? 2 / 1 : 3 / 1,
                  width: "100%",
                  borderRadius: "1rem",
                  objectFit: "fill",
                }}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default Banner;
