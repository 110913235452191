import React from "react";
import { Box } from "@mui/material";
import Navigation from "../../../Components/Navigation";
import { Outlet } from "react-router-dom";
import Home from "../components/home/Home";
import NewFooter from "../../../layout/NewFooter";
import Header from "../../../layout/navigation/Header";
import Navbar from "../../../layout/navigation/Navbar";

const HomePage = () => {
  return (
    <Box sx={{ minHeight: "100vh", backgroundColor: "white" }}>
      <Header />
      <Navbar />
      <Home />
      <NewFooter />
    </Box>
  );
};

export default HomePage;
