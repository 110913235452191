import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ResponsiveContainer from "../../../../muicomponents/shared/ResponsiveConatiner";
import HomePageContainer from "../../../home/components/home/shared/HomePageContainer";
import AllModels from "./allModels/AllModels";
import SeriesFilter from "./seriesFilter/SeriesFilter";
import ModelHeading from "./modelHeading/ModelHeading";

const AllBrandModels = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <ResponsiveContainer
      sx={{ minHeight: "calc(100vh - 137px)", minWidth: "300px" }}
    >
      <Box
        margin={isSmallScreen ? "1rem 0" : "3rem 0"}
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        gap={isSmallScreen ? "1rem" : "2rem"}
      >
        <HomePageContainer
          sx={{
            margin: isSmallScreen ? "0 1rem" : "0",
          }}
        >
          <ModelHeading />
        </HomePageContainer>
        <Box
          sx={{
            margin: isSmallScreen ? "0 1rem" : "0",
            display: "flex",
            gap: "2rem",
          }}
        >
          {!isSmallScreen && (
            <Box
              flexBasis={"25%"}
              sx={{ margin: isSmallScreen ? "0 1rem" : "0" }}
            >
              <SeriesFilter />
            </Box>
          )}

          <Box
            sx={{ margin: isSmallScreen ? "0" : "0", height: "100%" }}
            flexBasis={isSmallScreen ? "100%" : "75%"}
          >
            <AllModels />
          </Box>
        </Box>
      </Box>
    </ResponsiveContainer>
  );
};

export default AllBrandModels;
