import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Spinner,
  Table,
  Dropdown,
  Card,
} from "react-bootstrap";
import swal from "sweetalert";
import {
  GET_ALL_BUY_ORDERS,
  QUERY_GET_ALL_BUY_ORDERS_FOR_ADMIN,
  QUERY_GET_BUYING_ORDERS_USERID,
} from "../../graphql/Query";
import { QUERY_GET_ALL_USER } from "../../graphql/Query";
import {
  DELETE_INVOICE,
  MUTATION_GET_USER,
  UPDATE_INVOICE,
} from "../../graphql/Mutations";
import { MUTATION_UPDATE_BUY_ORDERS_BY_ID } from "../../graphql/Mutations";
import { useMutation, useQuery } from "@apollo/client";

import { FaEye, FaPen } from "react-icons/fa";
import React, { useRef, useState } from "react";
import CustomTypography from "../../muicomponents/shared/CustomTypography";
import { Box, CircularProgress } from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import MuiBackdrop from "../../muicomponents/shared/MuiBackdrop";
import { useSetRecoilState } from "recoil";
import { cartPageBackdrop } from "../../store/atoms/addToCart/addToCart";

const AllBuyingOrders = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [show2, setShow2] = useState(false);
  const fileInputRef = useRef(null);

  const [productFileData, setProductFileData] = useState({});

  const [deleteInvoice, { loading: deleteInvoiceLoading }] = useMutation(
    DELETE_INVOICE,
    {
      refetchQueries: [QUERY_GET_ALL_BUY_ORDERS_FOR_ADMIN],
    }
  );
  const [uploadInvoice, { loading: invoiceLoading }] = useMutation(
    UPDATE_INVOICE,
    {
      refetchQueries: [QUERY_GET_ALL_BUY_ORDERS_FOR_ADMIN],
    }
  );
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [errorMessage, setErrorMessage] = useState("");
  const [file, setFile] = useState(null);
  const handleClose = () => setShow(false);
  const handleClose2 = () => {
    setShow2(false);
    setSelectedOrder(null);
  };
  const [fileName, setFileName] = useState("");
  const [lastOrderStatus, setLastOrderStatus] = useState();
  const [paidAmount, setPaidAmount] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const userId = localStorage.getItem("userId");
  const [orderStatus, setOrderStatus] = useState("");

  const [
    updateBuyOrderStatusById,
    { loading: orderStatusLoading, error: orderStatusError },
  ] = useMutation(MUTATION_UPDATE_BUY_ORDERS_BY_ID, {
    refetchQueries: [QUERY_GET_ALL_BUY_ORDERS_FOR_ADMIN],
  });

  const getInvoiceName = (invoiceLink) => {
    const parts = invoiceLink.split("/");
    return parts[parts.length - 1];
  };

  const handleDeleteInvoice = async (order) => {
    try {
      const orderId = order.order.id;
      const { data } = await deleteInvoice({
        variables: {
          orderId,
        },
      });

      swal({
        title: "Success",
        text: data.deleteInvoice.message,
        icon: "success",
      });
    } catch (error) {
      console.error("Error Deleting invoice:", error);
    }
  };

  const getActiveItemsCount = (items) =>
    items.filter(
      (item) =>
        item.unit.status[item.unit.status.length - 1].status !== "cancelled"
    ).length;

  const handleFileChange = (event, itemId) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;

    setProductFileData((prevData) => ({
      ...prevData,
      [itemId]: {
        file: selectedFile,
        fileName: selectedFile.name,
        uploadStatus: "idle",
        errorMessage: "",
      },
    }));
  };

  const handleInvoiceUpload = async (item) => {
    const invoiceData = productFileData[item.id];
    if (!invoiceData || !invoiceData.file) return;

    const formData = new FormData();
    formData.append("file", invoiceData.file);

    const orderId = item.order.id;

    try {
      setBackdropOpen(true);
      const { data } = await uploadInvoice({
        variables: {
          orderId,
          file: formData.get("file"),
        },
      });

      swal({
        title: "Success",
        text: data.uploadOrderInvoice.message,
        icon: "success",
      });
      setProductFileData((prevData) => ({
        ...prevData,
        [item.id]: {
          ...invoiceData,
          file: null,
          fileName: "",
          uploadStatus: "success",
          errorMessage: "",
        },
      }));
    } catch (error) {
      console.error("Error uploading invoice:", error);
      setUploadStatus("error");
      setErrorMessage("Error uploading invoice");
    } finally {
      setBackdropOpen(false);
    }
  };
  const {
    data: getAllItems,
    loading,
    error,
    refetch,
  } = useQuery(QUERY_GET_ALL_BUY_ORDERS_FOR_ADMIN);

  console.log(getAllItems);

  let count = 1;
  const { data: getBuyorders, loading: OrderLoading } =
    useQuery(GET_ALL_BUY_ORDERS);

  const { data: AllUserData, loading: Alluserloading } =
    useQuery(QUERY_GET_ALL_USER);
  const handleStatusUpdate = (id, data) => {
    setSelectedOrder(data);
    setOrderStatus(
      data?.order?.payments[data?.order?.payments?.length - 1].paymentStatus ===
        "pending" &&
        data?.order?.payments[data?.order?.payments?.length - 1].paymentType !==
          "remaining" &&
        data.order.orderType !== "cod"
        ? "pending"
        : data?.unit?.status[data?.unit?.status.length - 1]?.status
    );
    setShow(true);
  };

  const handleSubmit = async () => {
    try {
      setBackdropOpen(true);
      const data = await updateBuyOrderStatusById({
        variables: {
          paymentStatus: selectedOrder.order.paymentStatus,
          orderType: selectedOrder.order.orderType,
          unitId: selectedOrder.unit.id,
          orderId: selectedOrder.order.id,
          status: orderStatus,
          timeline: selectedOrder.orderTimelineName,
          currentStatus:
            selectedOrder.unit.status[selectedOrder.unit.status.length - 1]
              .status,
          itemId: selectedOrder.id,
        },
      });
      swal({
        title: "Success",
        text: data.data.updateBuyOrderStatusById.message,
        icon: "success",
      });
    } catch (error) {
      let errorMessage = "An unexpected error occurred.";
      if (
        error.networkError &&
        error.networkError.result &&
        error.networkError.result.errors
      ) {
        errorMessage = error.networkError.result.errors[0].message;
      } else if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        errorMessage = error.graphQLErrors[0].message;
      } else if (error.message) {
        errorMessage = error.message;
      }
      swal({
        title: "Error!!!",
        text: errorMessage,
        icon: "error",
      });
    } finally {
      setBackdropOpen(false);
      handleClose();
    }
  };
  const handleOrderDetail = (order) => {
    console.log(order);
    let calculatedPaidAmount = 0;
    if (
      order.order.paymentStatus !== "failed" &&
      order.order.paymentStatus !== "unpaid"
    ) {
      if (order.order.orderType === "full") {
        calculatedPaidAmount = order.product.productPrice;
      } else if (order.order.orderType === "convenience") {
        if (order.order.remainingAmount === 0) {
          calculatedPaidAmount = order.product.productPrice;
          console.log(order.order.coupon);
          if (order.order.coupon) {
            const couponDiscount =
              order.order.couponDiscount / order.order.items.length;
            calculatedPaidAmount -= couponDiscount;
          }
        } else {
          calculatedPaidAmount = order.order.convenienceFee;
          if (order.order.coupon) {
            const couponDiscount =
              order.order.couponDiscount / order.order.items.length;
            calculatedPaidAmount -= couponDiscount;
          }
        }
      } else if (order.order.orderType === "advance") {
        if (order.order.remainingAmount === 0) {
          const finalAdvanceFee =
            order.order.advanceFee / order.order.items.length;

          calculatedPaidAmount =
            order.order.totalAmount - order.order.advanceFee;
        } else {
          calculatedPaidAmount = order.order.advanceFee;
        }
      } else if (order.order.orderType === "cod") {
        if (order.order.remainingAmount === 0) {
          calculatedPaidAmount = order.product.productPrice;
        } else {
          calculatedPaidAmount = 0;
        }
      }
    }
    setPaidAmount(calculatedPaidAmount);
    setSelectedOrder(order);
    setShow2(true);
  };
  const getStatus = (data) => {
    if (!data?.unit?.status || data.unit.status.length === 0) {
      return "pending";
    }

    const lastStatus = data.unit.status[data.unit.status.length - 1].status;

    if (lastStatus === "cancelled") {
      return "cancelled";
    }

    return data?.order?.paymentStatus || "N/A";
  };

  return (
    <>
      <MuiBackdrop />
      <h1
        style={{
          fontSize: 20,
          textAlign: "center",
          fontWeight: "bold",
          marginTop: 20,
        }}
      >
        Buying Orders
      </h1>
      <div style={{ overflowX: "auto", marginTop: "20px" }}>
        <Table bordered style={{ minWidth: "1000px" }}>
          <thead className="table-head">
            <tr>
              <th>s.no.</th>
              <th>Order No</th>
              <th>Item Id</th>
              <th>Order Type</th>
              <th>Payment Method</th>
              <th>Payment Status</th>
              <th>User Name </th>
              <th>contact / alternative contact </th>
              <th style={{ width: "300px" }}>Address</th>
              <th>Status</th>
              <th>Order Details</th>
              <th>Add Invoice</th>
            </tr>
          </thead>
          <tbody>
            {OrderLoading ? (
              <Spinner
                animation="border"
                variant="success"
                className="mx-auto d-block"
              />
            ) : (
              getAllItems &&
              getAllItems.getAllBuyOrdersForAdmin
                .slice(0)
                .reverse()
                .map((data) => (
                  <tr className="table-data">
                    <td>{count++}</td>
                    <td> {data?.order?.orderNo} </td>
                    <td>{data?.itemId}</td>
                    <td>{data?.order?.orderType}</td>
                    <td>
                      {" "}
                      {
                        data?.order?.payments[data?.order.payments?.length - 1]
                          .paymentMethod
                      }{" "}
                    </td>
                    <td>{getStatus(data)}</td>

                    <td>
                      {data?.user?.fName} {data?.user.lName}
                    </td>
                    <td>
                      {data?.order?.address?.contact}
                      {data?.order?.address?.alternateContactNo
                        ? `/${data.order.address.alternateContactNo}`
                        : null}
                    </td>
                    <td style={{ width: "250px" }}>
                      {`Name: ${data?.order?.address?.name}`}
                      <br />
                      {`Address: ${data?.order?.address?.address}, ${data?.order?.address?.cityDistrict}, ${data?.order?.address?.state}, ${data?.order?.address?.pinCode}`}
                      <br />
                      {data?.order?.address?.landMark && (
                        <>
                          {`Landmark: ${data.order.address.landMark}`}
                          <br />
                        </>
                      )}
                      {`Email: ${data?.order?.address?.email}`}
                      <br />
                      {data?.order?.address?.gst && (
                        <>
                          {`GST: ${data.order.address.gst}`}
                          <br />
                        </>
                      )}
                    </td>
                    <td>
                      {data?.order?.payments[data?.order?.payments?.length - 1]
                        .paymentStatus === "pending" &&
                      data?.order?.payments[data?.order?.payments?.length - 1]
                        .paymentType !== "remaining" &&
                      data.order.orderType !== "cod"
                        ? "pending"
                        : data?.unit?.status?.length === 0
                        ? "failed"
                        : data?.unit?.status[data?.unit?.status.length - 1]
                            ?.status}
                      <span
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                        onClick={() => handleStatusUpdate(data.id, data)}
                      >
                        {" "}
                        <FaPen />{" "}
                      </span>
                    </td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => handleOrderDetail(data)}
                    >
                      {" "}
                      <FaEye />{" "}
                    </td>
                    <td>
                      {data?.unit?.status[data?.unit?.status.length - 1]
                        ?.status === "delivered" ? (
                        <>
                          {data.order.invoice !== null ? (
                            <>
                              <Box
                                display={"flex"}
                                flexDirection={"column"}
                                gap={"0.5rem"}
                              >
                                {getInvoiceName(data.order.invoice)}
                                <FaEye
                                  onClick={() =>
                                    window.open(
                                      `${`https://hellofi.s3.ap-south-1.amazonaws.com/`}${
                                        data.order.invoice
                                      }`,
                                      "_blank"
                                    )
                                  }
                                />
                                <button
                                  onClick={() => handleDeleteInvoice(data)}
                                  style={{
                                    border: "1px solid black",
                                    outline: "none",
                                    padding: "0.2rem 1rem",
                                    borderRadius: "0.5rem",
                                  }}
                                >
                                  delete
                                </button>
                              </Box>
                            </>
                          ) : (
                            <>
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                                gap="0.5rem"
                              >
                                <input
                                  type="file"
                                  id={`file-invoice-${data.id}`}
                                  onChange={(event) =>
                                    handleFileChange(event, data.id)
                                  }
                                  style={{ display: "none" }}
                                  accept=".pdf"
                                />
                                <Button
                                  onClick={() =>
                                    document
                                      .getElementById(`file-invoice-${data.id}`)
                                      .click()
                                  }
                                  startIcon={
                                    loading ? (
                                      <CircularProgress size={20} />
                                    ) : (
                                      <FileUpload />
                                    )
                                  }
                                >
                                  <CustomTypography
                                    variant="subtitle2"
                                    sx={{ fontSize: "0.8rem" }}
                                  >
                                    {loading ? "Uploading..." : "Add Invoice"}
                                  </CustomTypography>
                                </Button>

                                {/* Display selected file name */}
                                {productFileData[data.id]?.fileName && (
                                  <Box>
                                    {productFileData[data.id].fileName.length >
                                    20
                                      ? productFileData[
                                          data.id
                                        ].fileName.substring(0, 20) + "..."
                                      : productFileData[data.id].fileName}
                                  </Box>
                                )}

                                {/* Display error message if any */}
                                {uploadStatus === "error" && (
                                  <Box
                                    sx={{ color: "red", fontSize: "0.75rem" }}
                                  >
                                    {errorMessage}
                                  </Box>
                                )}
                                {productFileData[data.id]?.file && (
                                  <button
                                    style={{
                                      outline: "none",
                                      border: "1px solid black",
                                      padding: "2px 1rem",
                                    }}
                                    onClick={() => handleInvoiceUpload(data)}
                                  >
                                    {uploadStatus === "uploading" ? (
                                      <CircularProgress
                                        size={20}
                                        color="inherit"
                                      />
                                    ) : (
                                      "Submit"
                                    )}
                                  </button>
                                )}
                              </Box>
                            </>
                          )}
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                ))
            )}
          </tbody>
        </Table>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Product Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Product Status
            </Dropdown.Toggle>
            <div style={{ fontWeight: "bold" }}>{orderStatus}</div>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setOrderStatus("shipped")}>
                Shipped
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setOrderStatus("outfordelivery")}>
                Out For Delivery
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setOrderStatus("delivered")}>
                Delivered
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setOrderStatus("cancelled")}>
                Cancelled
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive striped bordered hover>
            <thead className="table-head">
              <th>Product Name </th>
              <th>Product Condition </th>
              <th>Product price </th>
              <th>Product quantity </th>
              <th>Product Image </th>
            </thead>
            <tbody>
              <>
                <tr>
                  <td> {selectedOrder?.product?.productName} </td>
                  <td> {selectedOrder?.product?.condition} </td>
                  <td> {selectedOrder?.product?.productPrice} </td>
                  <td> {selectedOrder?.quantity} </td>
                  <td>
                    <Image
                      fluid
                      src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${selectedOrder?.product?.image[0]}`}
                      style={{ height: "4rem", margin: "3px" }}
                    ></Image>{" "}
                  </td>
                </tr>
              </>
              <tr className="fw-bold">
                <td>Total</td>

                <td>Rs. {selectedOrder?.product?.productPrice}</td>
              </tr>
              <tr className="fw-bold">
                <td>Coupon Discount</td>

                <td>
                  Rs.
                  {selectedOrder?.order?.couponDiscount === 0
                    ? 0
                    : selectedOrder?.order?.couponDiscount /
                      selectedOrder?.order?.items?.length}
                </td>
              </tr>
              {selectedOrder?.order?.orderType === "advance" &&
                selectedOrder?.order?.paymentStatus !== "failed" &&
                selectedOrder?.order?.paymentStatus !== "unpaid" && (
                  <tr className="fw-bold">
                    <td>Advance Fee (Paid)</td>
                    <td>
                      {" "}
                      Rs.
                      {selectedOrder?.order?.advanceFee /
                        selectedOrder.order.items.length}
                    </td>
                  </tr>
                )}
              {selectedOrder?.order?.orderType === "convenience" &&
                selectedOrder?.order?.paymentStatus !== "failed" &&
                selectedOrder?.order?.paymentStatus !== "unpaid" && (
                  <tr className="fw-bold">
                    <td>Convenience Fee (Paid)</td>
                    <td>
                      {" "}
                      Rs.
                      {selectedOrder?.order?.convenienceFee}
                    </td>
                  </tr>
                )}

              {selectedOrder?.order?.remainingAmount === 0 &&
                selectedOrder?.unit?.status[
                  selectedOrder?.unit?.status.length - 1
                ].status !== "cancelled" && (
                  <tr className="fw-bold">
                    <td>Paid Amount </td>
                    <td>
                      {" "}
                      Rs.
                      {paidAmount}
                    </td>
                  </tr>
                )}

              {(selectedOrder?.order?.orderType === "convenience" ||
                selectedOrder?.order?.orderType === "advance") &&
                selectedOrder?.order?.remainingAmount > 0 &&
                selectedOrder.unit.status[selectedOrder.unit.status.length - 1]
                  .status !== "cancelled" &&
                selectedOrder?.order?.paymentStatus !== "failed" &&
                selectedOrder?.order?.paymentStatus !== "unpaid" && (
                  <tr className="fw-bold">
                    <td> Remaining Amount (to be paid) </td>
                    <td>
                      {" "}
                      Rs.
                      {selectedOrder?.order?.remainingAmount}
                    </td>
                  </tr>
                )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AllBuyingOrders;
