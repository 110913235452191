import { Box } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const SeriesItem = ({ series }) => {
  return (
    <Box sx={{ padding: "1rem" }}>
      <CustomTypography variant={"subtitle2"}>
        {series.seriesName}
      </CustomTypography>
    </Box>
  );
};

export default SeriesItem;
