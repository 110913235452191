import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import React, { useRef } from "react";
import { GET_ALL_NEWS } from "../../../../../graphql/Query";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import NewsCard from "./NewsCard";

const AllNews = () => {
  const { data, loading, error } = useQuery(GET_ALL_NEWS);
  const handlePrevClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const swiperRef = useRef(null);

  if (loading) {
    return <>Loading....</>;
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          position: "relative",
          height: "100%",
          width: "100%",
        }}
      >
        <Swiper
          ref={swiperRef}
          modules={[Navigation, Pagination]}
          navigation={{
            nextEl: ".news-swiper-button-next",
            prevEl: ".news-swiper-button-prev",
          }}
          spaceBetween={20}
          slidesPerView={2}
          slidesPerGroup={1}
          loop={false}
          breakpoints={{
            0: {
              slidesPerView: 1.2,
              spaceBetween: 10,
            },
            600: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            821: {
              slidesPerView: 2.6,
              spaceBetween: 20,
            },
            1025: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
          }}
          className="news-cards"
          style={{
            "--swiper-navigation-color": "#000",
            "--swiper-pagination-color": "#000",
          }}
        >
          <div className="swiper-wrapper">
            {data?.getAllNews?.map((news) => (
              <SwiperSlide key={news.id}>
                <NewsCard
                  description={news.description}
                  title={news.title}
                  link={news.newsLink}
                  imageKey={news.imageKey}
                />
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
        <Box
          className="news-swiper-controls"
          sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            zIndex: 10,
            top: "50%",
            transform: "translateY(-50%)",
            left: 0,
            right: 0,
            padding: "0 2rem",
          }}
        >
          <button
            className="news-swiper-button-prev"
            aria-label="Previous"
            style={{ border: "2px solid grey", cursor: "pointer" }}
            onClick={handlePrevClick}
          >
            <ArrowBackIosNew fontSize="medium" sx={{ color: "grey" }} />
          </button>
          <button
            className="news-swiper-button-next"
            aria-label="Next"
            style={{ border: "2px solid grey", cursor: "pointer" }}
            onClick={handleNextClick}
          >
            <ArrowForwardIos fontSize="medium" sx={{ color: "grey" }} />
          </button>
        </Box>
      </Box>
      <style jsx>{`
        .news-cards {
          width: 100% !important;
          position: relative;
          padding: 0.5rem;
        }

        .news-cards .swiper-wrapper {
          display: flex;
          width: 100%;
        }

        .news-cards .swiper-slide {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-shrink: 0;
        }

        .news-cards .swiper-slide .news-card {
          transition: all 0.3s ease;
          width: 100%;
        }

        .news-swiper-button-next,
        .news-swiper-button-prev {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-color: rgba(255, 255, 255, 0.8);
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid grey;
          transition: opacity 0.3s;
          z-index: 10;
          cursor: pointer;
        }

        .news-swiper-button-prev {
          left: -25px;
        }

        .news-swiper-button-next {
          right: -25px;
        }

        @media (max-width: 1025px) {
          .news-swiper-button-prev,
          .news-swiper-button-next {
            display: none;
          }
        }
      `}</style>
    </Box>
  );
};

export default AllNews;
