import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { brandCategoryState } from "../../../../../store/atoms/selling/brands/brandsAtom";

const BrandCard = ({ brand }) => {
  const [brandCategory, setBrandCategory] = useRecoilState(brandCategoryState);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:766px)");
  const handleNavigate = () => {
    setBrandCategory({ brandId: 3, categoryId: 10 });
    navigate(
      `${window.location.pathname}/sell-${brand.brandName.toLowerCase()}`
    );
  };
  return (
    <Box
      onClick={handleNavigate}
      display={"flex"}
      className={"brand-card"}
      sx={{
        position: "relative",
        padding: "1rem",
        borderRadius: "1.875rem",
        boxShadow:
          "0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        justifyContent: "center",
        backgroundColor: "white",
        alignItems: "center",
        width: "100%",
        height: isSmallScreen ? "115px" : "140px",
        overflow: "hidden",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        "&:hover": {
          cursor: "pointer",
          transform: "scale(1.05)",
        },
      }}
    >
      <img
        src={`https://hellofi.s3.ap-south-1.amazonaws.com/${brand.brandImage}`}
        alt={`${brand.brandName} logo`}
        style={{
          width: "100%",
          height: isSmallScreen ? "70px" : "90px",
          objectFit: "contain",
        }}
      />
    </Box>
  );
};

export default BrandCard;
