import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const ModelBrand = ({ product }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("tabletMedium"));
  return (
    <Box
      sx={{
        borderRadius: "15px",
        backgroundColor: "#E0E9F1",
        padding: "0.25rem",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: isSmallScreen ? "80px" : "150px",
        }}
      >
        <img
          src={`https://hellofi.s3.ap-south-1.amazonaws.com/${product.image}`}
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </Box>
      <Box
        sx={{
          padding: isSmallScreen
            ? "0 0.5rem 0.5rem 0.5rem"
            : "0 1rem 1rem 1rem",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CustomTypography
          variant={"subtitle2"}
          sx={{
            fontWeight: "400",
            fontSize: { xs: "0.65rem", laptop: "0.75rem" },
            textAlign: "center",
          }}
        >
          {product.productName}
        </CustomTypography>
      </Box>
    </Box>
  );
};

export default ModelBrand;
