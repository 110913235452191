const getBrandName = {
  "mobile phone": "Phones",
  laptop: "Laptops",
  tablet: "Tablets",
  "smart watch": "Smart Watches",
  imac: "Desktop/Imac",
  "other-accessories": "other-accessories",
};

export { getBrandName };
