import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../muicomponents/shared/CustomTypography";
import Logo from "../Images/homepage/logo.png";
import {
  Facebook,
  WhatsApp,
  Twitter,
  Instagram,
  LinkedIn,
  Copyright,
} from "@mui/icons-material";
import ResponsiveContainer from "../muicomponents/shared/ResponsiveConatiner";

const NewFooter = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const isTabletScreen = useMediaQuery("(max-width:1025px)");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minWidth: "300px",
        minHeight: "400px",
        marginBottom: isSmallScreen ? "75px" : "0",
        backgroundColor: "#0A2544",
        overflow: "hidden",
      }}
    >
      <ResponsiveContainer
        sx={{ margin: { xs: "0 2rem", extraLarge: "0 auto" } }}
      >
        <Box display={"flex"} sx={{ width: "100%", padding: "2rem 0" }}>
          <Box
            sx={{
              flex: !isTabletScreen ? "0 0 70%" : "0 0 100%",
              borderBottom: isTabletScreen ? "none" : "2px solid white",
              height: isTabletScreen ? "100%" : "150px",
            }}
          >
            <Grid container spacing={{ xs: 5, tablet: 5 }}>
              <Grid item xs={12} tabletMedium={6} desktop={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: { xs: "center", tabletMedium: "flex-start" },
                  }}
                >
                  <CustomTypography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "600",
                      color: "white",
                    }}
                  >
                    FAQ
                  </CustomTypography>
                  <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                    Career
                  </CustomTypography>
                  <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                    About Us
                  </CustomTypography>
                  <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                    Term & Conditions
                  </CustomTypography>
                </Box>
              </Grid>
              <Grid item xs={12} tabletMedium={6} desktop={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: { xs: "center", tabletMedium: "flex-start" },
                  }}
                >
                  <CustomTypography
                    variant="subtitle1"
                    sx={{ fontWeight: "600", color: "white" }}
                  >
                    PRIVACY POLICY
                  </CustomTypography>
                  <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                    Shipping and delivery policy
                  </CustomTypography>
                  <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                    Return and Refund Policy
                  </CustomTypography>
                  <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                    GST Number - 29AAQFH3388A1Z4
                  </CustomTypography>
                </Box>
              </Grid>
              <Grid item xs={12} tabletMedium={6} desktop={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: { xs: "center", tabletMedium: "flex-start" },
                  }}
                >
                  <CustomTypography
                    variant="subtitle1"
                    sx={{ fontWeight: "600", color: "white" }}
                  >
                    CUSTOMER CARE
                  </CustomTypography>
                  <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                    Timings: 11AM - 8PM (MON - SUN)
                  </CustomTypography>
                  <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                    Call: +91 8150835583
                  </CustomTypography>
                  <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                    E-mail: contact@hellofi.in
                  </CustomTypography>
                </Box>
              </Grid>

              {isTabletScreen && (
                <Grid item xs={12} tabletMedium={6} desktop={4}>
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box sx={{ height: "150px", marginBottom: "1rem" }}>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.676546847998!2d77.63344577377502!3d12.928498587382904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae150c3f13efb3%3A0x7c63fb5ed43e5ec!2sHelloFi%20Recommerce!5e0!3m2!1sen!2sin!4v1732017338621!5m2!1sen!2sin"
                        style={{
                          border: "0",
                          width: "100%",
                          height: "150px",
                          borderRadius: "0.5rem",
                        }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </Box>

                    <Box
                      sx={{
                        margin: "0 auto",
                        display: "flex",
                        justifyContent: "center",
                        gap: "1rem",
                        zIndex: 2,
                      }}
                    >
                      <Facebook
                        style={{
                          fontSize: "1.5rem",
                          color: "white",
                          marginRight: "1rem",
                        }}
                      />

                      <WhatsApp
                        style={{
                          fontSize: "1.5rem",
                          color: "white",
                          marginRight: "1rem",
                        }}
                      />

                      <Instagram
                        style={{
                          fontSize: "1.5rem",
                          color: "white",
                          marginRight: "1rem",
                        }}
                      />
                      <Twitter
                        style={{
                          fontSize: "1.5rem",
                          color: "white",
                          marginRight: "1rem",
                        }}
                      />
                      <LinkedIn
                        style={{
                          fontSize: "1.5rem",
                          color: "white",
                          marginRight: "1rem",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>

          {!isTabletScreen && (
            <Box
              sx={{
                flex: !isTabletScreen ? "0 0 30%" : "0 0 100%",
                paddingLeft: "1rem",
              }}
            >
              <Box sx={{ height: "150px", marginBottom: "1rem" }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.676546847998!2d77.63344577377502!3d12.928498587382904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae150c3f13efb3%3A0x7c63fb5ed43e5ec!2sHelloFi%20Recommerce!5e0!3m2!1sen!2sin!4v1732017338621!5m2!1sen!2sin"
                  style={{
                    border: "0",
                    width: "100%",
                    height: "150px",
                    borderRadius: "0.5rem",
                  }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </Box>

              <Box
                sx={{
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "center",
                  gap: "1rem",
                  zIndex: 2,
                }}
              >
                <Facebook
                  style={{
                    fontSize: "1.5rem",
                    color: "white",
                    marginRight: "1rem",
                  }}
                />

                <WhatsApp
                  style={{
                    fontSize: "1.5rem",
                    color: "white",
                    marginRight: "1rem",
                  }}
                />

                <Instagram
                  style={{
                    fontSize: "1.5rem",
                    color: "white",
                    marginRight: "1rem",
                  }}
                />
                <Twitter
                  style={{
                    fontSize: "1.5rem",
                    color: "white",
                    marginRight: "1rem",
                  }}
                />
                <LinkedIn
                  style={{
                    fontSize: "1.5rem",
                    color: "white",
                    marginRight: "1rem",
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            borderBottom: "2px solid white",
            paddingBottom: "2rem",
          }}
        >
          <Grid container spacing={{ xs: 5, tablet: 5 }}>
            <Grid item xs={12} tabletMedium={6} desktop={3}>
              <Box
                sx={{
                  display: { xs: "flex", tabletMedium: "block" },
                  justifyContent: { xs: "center" },
                }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "1rem",
                    padding: "0.5rem",
                    width: "70%",
                  }}
                >
                  <img src={Logo} style={{ width: "140px", height: "50px" }} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} tabletMedium={6} desktop={3}>
              <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                HelloFi - An online platform to sell your old mobile phones,
                tablets, iPads, laptops, cameras, consoles, other gadgets &
                devices for instant cash.
              </CustomTypography>
            </Grid>
            <Grid item xs={12} tabletMedium={6} desktop={3}>
              <CustomTypography
                variant="subtitle1"
                sx={{
                  fontWeight: "600",
                  color: "white",
                  paddingBottom: "0.5rem",
                  borderBottom: "2px solid #0F9DAC",
                }}
              >
                Sell Phones | Laptops | Tablets
              </CustomTypography>
              <CustomTypography
                variant="subtitle2"
                sx={{ color: "white", paddingTop: "0.5rem" }}
              >
                sell Old Mobile Phones
              </CustomTypography>
              <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                sell apple iPhone
              </CustomTypography>
              <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                sell Old Laptops
              </CustomTypography>
              <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                sell old apple macbook
              </CustomTypography>
              <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                sell Tablets
              </CustomTypography>
              <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                sell apple iPad
              </CustomTypography>
              <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                sell used Samsung tablets
              </CustomTypography>
            </Grid>
            <Grid item xs={12} tabletMedium={6} desktop={3}>
              <CustomTypography
                variant="subtitle1"
                sx={{
                  fontWeight: "600",
                  color: "white",
                  paddingBottom: "0.5rem",
                  borderBottom: "2px solid #0F9DAC",
                }}
              >
                Buy Used Phones | Used Laptops | Used Tablets
              </CustomTypography>
              <CustomTypography
                variant="subtitle2"
                sx={{ color: "white", paddingTop: "0.5rem" }}
              >
                Buy old laptop
              </CustomTypography>
              <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                buy apple macbook
              </CustomTypography>
              <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                buy apple iphone
              </CustomTypography>
              <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                mobile for cash
              </CustomTypography>
              <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                buy used tablet
              </CustomTypography>
              <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                buy apple smartwatch
              </CustomTypography>
              <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
                buy second hand mobile 
              </CustomTypography>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            padding: "1rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomTypography variant="subtitle2" sx={{ color: "white" }}>
            <Copyright sx={{ fontSize: "1rem" }} /> Copyright 2024 HelloFi
            Recommerce
          </CustomTypography>
        </Box>
      </ResponsiveContainer>
    </Box>
  );
};

export default NewFooter;
